import {
  Button,
  Heading,
  HStack,
  Textarea,
  Card,
  Box,
  Flex,
  Spacer,
  Stack,
  Text,
  Divider,
  SimpleGrid,
  VStack,
  Code,
} from "@chakra-ui/react";
import { Avatar as ChakraAvatar } from "@chakra-ui/react";
import PostsLists from "components/post/PostsList";
import { useAuth } from "hooks/auth";
import { useAddPost, usePosts } from "hooks/posts";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { useState, useEffect } from "react";
import { storage } from "lib/firebase";
import { ref, uploadBytes, listAll } from "firebase/storage";
import { useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { Bars, InfinitySpin } from "react-loader-spinner";
import Avatar from "components/profile/Avatar";
import { wrap } from "framer-motion";
import axios from "axios";
import { baseUrl } from "services/api";
import { RxGear } from "react-icons/rx";

export function NewPost() {
  const { register, handleSubmit, reset } = useForm();
  const { addPost, isLoading: addingPost } = useAddPost();
  const { user, isLoading: authLoading } = useAuth();
  const toast = useToast();

  function handleAddPost(data) {
    addPost({
      uid: user.id,
      text: data.text,
    });
    reset();
  }
  const [imageUpload, setImageUpload] = useState(null);

  const uploadImage = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `postImage/${imageUpload.name + Date.now()}`);
    uploadBytes(imageRef, imageUpload).then(() => {
      toast({
        title: "Post uploaded successfully!",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    });
  };

  const imageListRef = ref(storage, "images/");
  const [imageList, setImageList] = useState(null);
  useEffect(() => {
    listAll(imageListRef).then((response) => { });
  }, []);

  return (
    <Card
      maxW="650px"
      mx="auto"
      p="20px"
      borderWidth="2px"
      borderColor="0,0,0,0.5"
    >
      <form onSubmit={handleSubmit(handleAddPost)}>
        <HStack justify="space-between">
          <Heading size="lg">New Post</Heading>
          <Button
            colorScheme="blue"
            type="submit"
            isLoading={authLoading || addingPost}
            loadingText="Loading"
            onClick={uploadImage}
          >
            Post
          </Button>
        </HStack>
        <Textarea
          as={TextareaAutosize}
          resize="none"
          mt="5"
          placeholder="Create a new post..."
          minRows={3}
          {...register("text", { required: true })}
        />
        <input
          type="file"
          onChange={(e) => {
            setImageUpload(e.target.files[0]);
          }}
        />
      </form>
    </Card>
  );
}

export default function Dashboard() {
  // const { posts, isLoading } = usePosts();
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  const [circles, setCircles] = useState([]);
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    if (user?.role.toLowerCase() === "admin") {
      axios.get(baseUrl + "/circles").then((res) => {
        if (res.data.status) {
          setCircles(res.data.result);
          setLoading(false);
        }
      });
    } else {
      axios
        .get(baseUrl + "/circlesUser/getCirclesByUserId/" + user?._id)
        .then((res) => {
          if (res.data.status) {
            const filter = res.data.result.map((r) => r.circleId);
            setCircles(filter);
            setLoading(false);
          }
        });
    }
  }, [user?._id, user?.role]);

  if (isLoading)
    return (
      <Box p="2" maxW="130px" textAlign="center" mx={"auto"} mt={"12"}>
        <InfinitySpin
          width="200"
          color="#008080"
          // style={{ marginLeft: "47%", display: "block" }}
        />
      </Box>
    );

  return (
    <>
      <Flex
        p={["4", "6"]}
        // pos="relative"
        alignItems={"center"}
        justifyContent={"space-between"}
        align="center"
        flexWrap={"wrap"}
      >
        <Box width={"70%"}>
          <Flex alignItems={"center"} flexWrap={"wrap"}>
            <Avatar user={user} size="2xl" />

            <Stack ml="10">
              <Text fontSize="2xl">{user?.username?.toUpperCase()}</Text>
              <HStack spacing="10">
                <Text color="gray.700" fontSize={["sm", "lg"]}>
                  Circles: {circles.length}
                </Text>
                <Text color="gray.700" fontSize={["sm", "lg"]}>
                  Joined: {new Date(user?.createdAt).toString().slice(0, 16)}
                </Text>
              </HStack>
            </Stack>
          </Flex>
        </Box>
        <Box mt={"20px"}>
          <Flex
            alignItems="center"
            gap="2"
            justifyContent={"space-around"}
            flexWrap={"wrap"}
          >
            {/* <Box width={"130px"}>
              {" "}
              <Button
                onClick={() => navigate("/protected/dashboard")}
                colorScheme="blue"
                width={"100%"}
              >
                HOMEPAGE
              </Button>
            </Box> */}

            {user?.role === "admin" && (
              <>
                <Box width={"150px"}>
                  {" "}
                  <Button
                    onClick={() => navigate("/protected/users")}
                    colorScheme="blue"
                    width={"100%"}
                  >
                    STAFF
                  </Button>
                </Box>
                <Box width={"150px"}>
                  {" "}
                  <Button
                    onClick={() => navigate("/protected/register")}
                    colorScheme="blue"
                    width={"100%"}
                  >
                    ADD MEMBERS
                  </Button>
                </Box>
              </>
            )}
            {user?.role === "admin" && (
              <Box width={"150px"}>
                {" "}
                <Button
                  onClick={() => navigate("/protected/allusers")}
                  colorScheme="blue"
                  width={"100%"}
                >
                  USERS
                </Button>
              </Box>
            )}
            <Box width={"150px"}>
              {" "}
              <Button
                onClick={() => navigate("/protected/circle")}
                colorScheme="blue"
                width={"100%"}
              >
                CIRCLES
              </Button>
            </Box>
            <Box width={"150px"}>
              {" "}
              <Button
                onClick={() => navigate("/protected/chat")}
                colorScheme="blue"
                width={"100%"}
              >
                CHAT
              </Button>
            </Box>
            <Box width={"150px"}>
              {" "}
              <Button
                onClick={() => navigate("/protected/caremanuals")}
                colorScheme="blue"
                width={"100%"}
              >
                CARE MANUALS
              </Button>
            </Box>
            {user?.role === "admin" && (
            <Box width={"150px"}>
              {" "}
              <Button
                onClick={() => navigate("/protected/notification")}
                colorScheme="blue"
                width={"100%"}
              >
                <RxGear style={{ marginRight: "1px" }} />
                Notifications
              </Button>
            </Box>
            )}
            {user?.role === "user" && (
            <Box width={"150px"}>
              {" "}
              <Button
                onClick={() => navigate("/protected/notification")}
                colorScheme="blue"
                width={"100%"}
              >
                <RxGear style={{ marginRight: "1px" }} />
                Notifications
              </Button>
            </Box>
            )}
          </Flex>
        </Box>
      </Flex>
      <Divider />
      <div className="card-style">
        {loading ? (
          <>
            <Box p="2" maxW="130px" textAlign="center" mx={"auto"} mt={"12"}>
              <Bars
                width="200"
                color="#008080"
                // style={{ marginLeft: "47%", display: "block" }}
              />
            </Box>
          </>
        ) : (
          <>
            {circles?.length === 0 ? (
              <>
                <Text textAlign={"center"} fontSize={"2xl"} mt={"4"}>
                  You are not joined to any circles
                </Text>
              </>
            ) : (
              <>
                <Box>
                  <SimpleGrid
                    columns={[2, 3, 6]}
                    spacing={[2, 3]}
                    px="10px"
                    py="6"
                  >
                    {circles
                      .filter((circle) => !circle.archive) // Filter out archived circles
                      .map((circle) => (
                        <VStack
                          bg="gray.100"
                          shadow="sm"
                          rounded="md"
                          textAlign="center"
                          p="4"
                          spacing="3"
                        >
                          <ChakraAvatar
                            as={Link}
                            name={circle?.firstName}
                            size={"xl"}
                            src={circle?.circleImg}
                            _hover={{ cursor: "pointer", opacity: "0.8" }}
                          />
                          <Code>
                            {circle?.firstName} {circle?.lastName}
                          </Code>
                          <Link>
                            <Button
                              as={Link}
                              to={`/protected/circlePost/${circle?._id}`}
                              size="sm"
                              variant="link"
                              colorScheme="blue"
                            >
                              Visit Circle
                            </Button>
                          </Link>
                        </VStack>
                      ))}
                  </SimpleGrid>
                </Box>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
