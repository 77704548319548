import { Box, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useAuth } from "hooks/auth";
import { CIRCLE, LOGIN, PROTECTED } from "lib/routes";
import React, { useEffect } from "react";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "services/api";

const Invite = () => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const { token } = useParams();
  const toast = useToast();

  const userLoginWithToken = async () => {
    try {
      const response = await axios.post(baseUrl + "/user/acceptInvite", {
        token,
      });
      toast({
        title: "You are logged in",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
        description: "You will stay logged in until you sign out",
      });
      // console.log("response", JSON.stringify(response.data));
      localStorage.setItem("token", response.data?.token);
      navigate(CIRCLE);
    } catch (error) {
      toast({
        title: "Failed",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      localStorage.removeItem("token");
      navigate(LOGIN);
    }
  };

  useEffect(() => {
    userLoginWithToken();
  }, [token]);
  // useEffect(() => {
  //   if (!token && user) {
  //     navigate(PROTECTED);
  //   }
  // }, [user, isLoading]);

  return (
    <Box p="2" maxW="150px" textAlign="center" mx={"auto"} mt={"12"}>
      <InfinitySpin width="200" color="#008080" />
    </Box>
  );
};

export default Invite;
