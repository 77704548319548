import axios from "axios";
import { baseUrl } from "./api";
import draftToHtml from "draftjs-to-html";

export const PostServices = {
  processPostObj: (post) => {
    try {
      const content = draftToHtml(JSON.parse(post.postBody));
      // console.log(content);
     return { ...post, content: content };
    } catch (err) {
      return { ...post, content: post.postBody, isText: true };
    }
  },
  processCarePostObj: (post) => {
    try {
      const content = draftToHtml(JSON.parse(post.carePostBody));
      // console.log(content);
     return { ...post, content: content };
    } catch (err) {
      return { ...post, content: post.carePostBody, isText: true };
    }
  },
  fetchPostById: async (id) => {
     return axios.get(baseUrl + "/posts/" + id).then((res) => {
      if (res.data.status) {
        return PostServices.processPostObj(res?.data?.result);
      }
    });
  },
  fetchPostByCircleId: (id, page) => {
    return axios
      .get(baseUrl + "/posts/getPostByCircleId/" + id + "?limit=5&page=" + page)
      .then((res) => {
        if (res.data.status) {
          const newPost = [];
          for (const i of res.data.result) {
              newPost.push(PostServices.processPostObj(i));
          }
          return { newPost, total: res.data.total };
        } else {
          return [];
        }
      });
  },
  fetchPostByProfileId: (id) => {
    return axios.get(baseUrl + "/posts/getPostByUserId/" + id).then((res) => {
        if (res.data.status) {
          const newPost = [];
          for (const i of res.data.result) {
              newPost.push(PostServices.processPostObj(i));
          }
          return newPost;
      } else {
          return [];
        }
      });   
  },
};
