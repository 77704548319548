import {
  Box,
  Button,
  Card,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import PostsList from "components/post/PostsList";
import Avatar from "components/profile/Avatar";
import UsernameButton from "components/profile/UsernameButton";
import { formatDistanceToNow } from "date-fns";
import { useAuth } from "hooks/auth";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEdit, FaTrash } from "react-icons/fa";
import Resizer from "react-image-file-resizer";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import { useParams } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { baseUrl } from "services/api";
import { AiOutlineHighlight } from "react-icons/ai";
const CareManualsPost = () => {
  const [visiblePosts, setVisiblePosts] = useState(5);

  const loadMorePosts = () => {
    setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 5);
  };
  const { register, handleSubmit, reset } = useForm();
  const { user, isLoading: authLoading } = useAuth();
  const toast = useToast();
  const { id } = useParams();
  const [refetch, setRefetch] = useState(0);
  const [postList, setPostList] = useState([]);
  const [imageUpload, setImageUpload] = useState("");
  const [pdfUpload, setPdfUpload] = useState("");
  const [desc, setDesc] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenThird,
    onOpen: onOpenThird,
    onClose: onCloseThird,
  } = useDisclosure();
  const [selected, setSelected] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorEditState, setEditorEditState] = useState(() =>
    EditorState.createEmpty()
  );
  const [selectedCircleId, setSelectedCircleId] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const handleEditorEditChange = (state) => {
    setEditorEditState(state);
  };
  // console.log('postLists', postList)
  async function handleAddPost(data) {
    const content = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    if (pdfUpload) {
      const formData = new FormData();
      formData.append("pdf", pdfUpload);
      await axios
        .post(baseUrl + "/posts/uploadPdf", formData)
        .then(async (res) => {
          if (res.data.status) {
            // console.log(res.data.data);
            const d = {
              carePostBody: content,
              carePostBy: user?._id,
              careManualsId: id,
              carePostImg: imageUpload,
              postPdf: res.data.fileNamePdf,
            };
            await axios.post(baseUrl + "/care-post", d).then((res) => {
              if (res.data.status) {
                toast({
                  title: "Posted",
                  description: res.data.message,
                  status: "success",
                  isClosable: true,
                  position: "top",
                  duration: 5000,
                });
                setEditorState("");
                if (page === 1) {
                  setRefetch(refetch + 1);
                } else {
                  setPage(1);
                }
                reset();
                setImageUpload("");
              } else {
                toast({
                  title: "Failed to Post",
                  description: res.data.message,
                  status: "error",
                  isClosable: true,
                  position: "top",
                  duration: 5000,
                });
              }
            });
          }
        });
    } else {
      const d = {
        carePostBody: content,
        carePostBy: user?._id,
        careManualsId: id,
        carePostImg: imageUpload,
      };
      await axios.post(baseUrl + "/care-post", d).then((res) => {
        if (res.data.status) {
          toast({
            title: "Posted",
            description: res.data.message,
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
          setEditorState("");
          if (page === 1) {
            setRefetch(refetch + 1);
          } else {
            setPage(1);
          }
          reset();
          setImageUpload("");
        } else {
          toast({
            title: "Failed to Post",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
      });
    }
  }

  const deletePost = async (Id) => {
    // console.log(post?._id);
    onCloseThird();
    await axios.delete(baseUrl + `/care-post/${Id}`).then((res) => {
      if (res.data.status) {
        toast({
          title: "Post Deleted",
          description: res.data.message,
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
        setRefetch(refetch + 1);
      } else {
        toast({
          title: "Failed to delete Post",
          description: res.data.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
  };

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp"
    ) {
      Resizer.imageFileResizer(
        file,
        700, // Max width
        700, // Max height
        "JPEG", // Output format
        100, // Quality
        0, // Rotation
        (base64Image) => {
          // Handle the optimized image (base64)
          setImageUpload(base64Image);
        },
        "base64" // Output type
      );
    } else {
      setPdfUpload(file);
    }
  };

  useEffect(() => {
    axios
      .get(
        baseUrl + "/care-post/getPostByCareId/" + id + "?limit=5&page=" + page
      )
      .then((res) => {
        if (res.data.status) {
          // setPostList(res.data.result);

          const newPost = [];
          for (const i of res.data.result) {
            const content = draftToHtml(JSON.parse(i.carePostBody));
            // console.log(content)
            newPost.push({ ...i, content: content });
          }
          // console.log(newPost)
          // setPostList(newPost);
          if (page === 1) {
            setPostList(newPost);
          } else {
            setPostList((prev) => [...prev, ...newPost]);
          }
          setTotal(res.data.total);
        }
      });
  }, [id, refetch, page]);

  // console.log("page", page, total);

  // console.log(convertToRaw(draftToHtml(editorState.getCurrentContent())))
  const handleDownload = async (fileName) => {
    try {
      const response = await fetch(`${baseUrl}/posts/downloadPdf/${fileName}`);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "example.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = async () => {
    let desc = JSON.stringify(
      convertToRaw(editorEditState.getCurrentContent())
    );
    await axios
      .patch(baseUrl + `/care-post/edit/${selected}`, { carePostBody: desc })
      .then((res) => {
        if (res.data.status) {
          // setEditorEditState("");
          toast({
            title: "Post Edited",
            description: res.data.message,
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
          setRefetch(refetch + 1);
          onClose();
        } else {
          toast({
            title: "Failed to edit Post",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
      });
  };
  const parseURLs = (content) => {
    // console.log("content", content);
    const urlRegex = /((?:https?:\/\/|www\.)[^\s]+)/g;
    const parsedContent = content.replace(urlRegex, (match) => {
      const decodedURL = decodeURIComponent(match);
      const url = decodedURL.split("<")[0];
      // console.log("decodeURL", url);
      if (decodedURL.startsWith("http")) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      } else {
        return `<a href="http://${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }
    });
    return parsedContent;
  };
  // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  return (
    <div style={{ marginTop: "30px" }}>
      <Card
        maxW="650px"
        mx="auto"
        p="20px"
        borderWidth="2px"
        borderColor="0,0,0,0.5"
      >
        <form onSubmit={handleSubmit(handleAddPost)}>
          <div style={{ padding: "10px" }}>
            <HStack justify="space-between">
              <Heading size="lg">New Post</Heading>
              <Button
                colorScheme="blue"
                type="submit"
                //   isLoading={authLoading || addingPost}
                loadingText="Loading"
              >
                Post
              </Button>
            </HStack>
          </div>

          <Box border="2px solid teal" marginTop={"5px"}>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              toolbar={{
                options: ["inline", "list"],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: [
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "monospace",
                  ],
                },
                list: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ["unordered", "ordered"],
                },
              }}
            />
          </Box>
          <Box marginTop={"30px"}>
            <input type="file" onChange={handleFileChange} />
          </Box>
        </form>
      </Card>
      <div>
        <Box px="4" align="center">
          {postList?.length === 0 ? (
            <Text textAlign="center" fontSize="xl">
              Loading Posts...
            </Text>
          ) : (
            postList?.map((post) => (
              <Box p="2" maxW="700px" textAlign="left">
                <Card
                  mx="auto"
                  p="10px"
                  borderWidth="2px"
                  borderColor="0,0,0,0.5"
                >
                  <Flex
                    borderBottom="2px solid"
                    borderColor="blue.100"
                    py="3"
                    bg="gray.50"
                    justifyContent="space-between"
                    alignItems={"start"}
                  >
                    {" "}
                    <Flex alignItems="center" px="3">
                      <Avatar user={post?.carePostBy} size="md" />

                      <Box ml="4">
                        <UsernameButton user={post?.carePostBy} />
                        <Text fontSize="sm" color="gray.500">
                          {post?.createdAt && (
                            <>
                              {formatDistanceToNow(new Date(post?.createdAt))}{" "}
                              ago
                            </>
                          )}
                        </Text>
                      </Box>
                    </Flex>
                    <Box
                      marginRight={"3"}
                      fontWeight={"bolder"}
                      color={"teal.400"}
                    >
                      {post?.careManualsId?.firstName}{" "}
                      {post?.careManualsId?.lastName}
                    </Box>
                  </Flex>
                  <Box p="2" minH="100px">
                    <Text
                      wordBreak="break-word"
                      fontSize="md"
                      paddingLeft={5}
                      paddingBottom={2}
                      paddingTop={2}
                    >
                      {/* {post?.carePostBody} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: parseURLs(post?.content),
                        }}
                      />
                    </Text>
                    {post?.postPdf && (
                      <Text
                        cursor={"pointer"}
                        fontWeight={"bolder"}
                        color={"blue.600"}
                        onClick={() => handleDownload(post?.postPdf)}
                      >
                        View Pdf
                      </Text>
                    )}
                  </Box>
                  {post?.carePostImg && (
                    <Box width={"100%"}>
                      <Image
                        width={"100%"}
                        src={post?.carePostImg}
                        alt="Dan Abramov"
                      />
                    </Box>
                  )}
                  <Box float={"right"}>
                    {user?.role?.toLowerCase() === "admin" && (
                      <IconButton
                        ml="auto"
                        onClick={() => {
                          onOpenThird();
                          setSelectedCircleId(post?._id);
                        }}
                        size="md"
                        colorScheme="red"
                        variant="ghost"
                        icon={<FaTrash />}
                        isRound
                      />
                    )}
                    {user?.role?.toLowerCase() === "admin" && (
                      <IconButton
                        ml="auto"
                        onClick={() => {
                          onOpen();
                          setEditorEditState(
                            EditorState.createWithContent(
                              convertFromRaw(JSON.parse(post?.carePostBody))
                            )
                          );
                          setSelected(post?._id);
                        }}
                        size="md"
                        colorScheme="red"
                        variant="ghost"
                        icon={<FaEdit />}
                        isRound
                      />
                    )}
                  </Box>
                </Card>
              </Box>
            ))
          )}
          {total > postList?.length && (
            <Button
              mt="4"
              colorScheme="blue"
              onClick={() => setPage((prev) => prev + 1)}
            >
              Load More
            </Button>
          )}
        </Box>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Post</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box border="2px solid teal" marginTop={"5px"}>
              <Editor
                editorState={editorEditState}
                onEditorStateChange={handleEditorEditChange}
                toolbar={{
                  options: ["inline", "list"],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: [
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "monospace",
                    ],
                  },
                  list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["unordered", "ordered"],
                  },
                }}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={handleEdit}
            >
              Update
            </Button>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenThird} onClose={onCloseThird}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to delete ?</ModalHeader>
          <ModalCloseButton />

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onCloseThird}
            >
              Cancel
            </Button>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="red"
              onClick={() => deletePost(selectedCircleId)}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CareManualsPost;
