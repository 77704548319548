import { useEffect, useState } from "react";
import { CIRCLE, HOMEPAGE, LOGIN } from "lib/routes";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "services/api";

export function useAuth() {
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState();
  const token = localStorage.getItem("token");
  const [reftech, setRefetch] = useState(0);
  const getUser = () => {
    setRefetch(reftech + 1);
  };
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await axios
        .post(`${baseUrl}/user/autosignin`, { token: token })
        .then((res) => {
          if (res.data.status) {
            setUser(res.data.result);
            setLoading(false);
          } else {
            setError(res.data.message);
          }
        });
    }
    if (token) fetchData();
    else setLoading(false); // Not signed in
  }, [token, reftech]);

  return { user, isLoading, error, getUser };
}

export function useLogin() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  async function login({ data, redirectTo = CIRCLE }) {
    setLoading(true);

    try {
      await axios.post(`${baseUrl}/user/signin`, data).then((res) => {
        if (res.data.status) {
          toast({
            title: "You are logged in",
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
            description: "You will stay logged in until you logout",
          });
          localStorage.setItem("token", res.data.token);
          navigate(redirectTo);
          setLoading(false);
        } else {
          toast({
            title: "Logging in failed",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
        setLoading(false);
      });
    } finally {
      setLoading(false);
    }
  }

  return { login, isLoading };
}

export function useRegister() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  async function register({ data, redirectTo = CIRCLE }) {
    setLoading(true);

    try {
      await axios.post(`${baseUrl}/user/signup`, data).then((res) => {
        if (res.data.status) {
          toast({
            title: "Account created",
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
          // localStorage.setItem("token", res.data.token);
          navigate(redirectTo);
          setLoading(false);
        } else {
          toast({
            title: "Account Creation Failed",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
        setLoading(false);
      });
    } catch (error) {
      toast({
        title: "Account Creation Failed",
        description: error.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  }

  return { register, isLoading };
}

export function useLogout() {
  // const [signOut, isLoading, error] = useSignOut(auth);
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");

  async function logout() {
    setIsLoading(true);
    if (token) {
      localStorage.removeItem("token");
      toast({
        title: "Successfully logged out",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      navigate(LOGIN);
      setIsLoading(false);
    } // else: show error [signOut() returns false if failed]
  }

  return { logout, isLoading };
}
