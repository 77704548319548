import {
  Box,
  Button,
  Card,
  Code,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { PROTECTED } from "lib/routes";
import Avatar from "components/profile/Avatar";
import { useAuth } from "hooks/auth";
import { FaTrash } from "react-icons/fa";
import { useState } from "react";
import { baseUrl } from "services/api";
import axios from "axios";
import { AiFillEdit } from "react-icons/ai";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";

export default function User({ user, UserMain, setUsers, users }) {
  const { _id, username } = user;

  const [editUsername, setEditUsername] = useState(username);

  const [editorState, setEditorState] = useState(() =>
    user?.bio
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(user?.bio)))
      : EditorState.createEmpty()
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  const toast = useToast();

  const deleteUserHandler = async (userId) => {
    try {
      const config = {
        method: "delete",
        url: `${baseUrl}/user/deleteUser/${_id}`,
      };
      // console.log(config);
      const { data } = await axios.request(config);
      // console.log(data);
      setUsers(users.filter((u) => u._id !== _id));
      onClose();
      toast({
        title: "User Deleted",
        description: data.message,
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } catch (error) {
      // console.log(error?.response?.data?.message);
      toast({
        title: "Failed to Delete User",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      onClose();
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const validateName = (name) => {
    const words = name.trim().split(" ");
    if (words.length < 2) {
      toast({
        title: "Failed to update user",
        description: "Please enter your first and last name",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      return false;
    }
    if (words.length > 2) {
      toast({
        title: "Failed to update user",
        description: "You must only add first and last name",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      return false;
  }
    return true;
  };

  const UpdateUserHandler = async (e) => {
    const isUsernameValid = validateName(editUsername);
    if (!isUsernameValid) {
      console.log("if (isUsernameValid) update");
      return;
    }
    e.preventDefault();
    const bio = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    try {
      const config = {
        method: "patch",
        url: `${baseUrl}/user/editUsername`,
        data: {
          userId: _id,
          username: editUsername,
          bio,
        },
      };
      // console.log(config);
      const { data } = await axios.request(config);
      const updatedUser = data.data;

      setUsers((prevUsers) => {
        return prevUsers.map((user) => {
          if (user._id === updatedUser._id) {
            return {
              ...user,
              username: updatedUser.username,
            };
            }
          return user;
        });
      });

      onCloseEdit();
      toast({
        title: "User Updated",
        description: data.message,
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } catch (error) {
      toast({
        title: "Failed to update user",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      onCloseEdit();
    }
  };

  return (
    <>
    <VStack
        bg="gray.100"
        shadow="sm"
        rounded="md"
        textAlign="center"
        p="4"
        spacing="3"
    >
      <Avatar user={user} />
      <Code>{username}</Code>
        <div style={{ display: "flex", alignItems: "center" }}>
          {UserMain?.role === "admin" && (
        <Link>
          <Button
            as={Link}
            to={`${PROTECTED}/profile/${_id}`}
                size="sm"
                variant="link"
                colorScheme="blue"
                // ml="40px"
          >
            View Profile
          </Button>
        </Link>
      )}
          {UserMain?.role?.toLowerCase() === "admin" &&
            UserMain._id !== user._id && (
              <IconButton
                ml="10px"
                // onClick={() => deleteUserHandler(user?._id)}
                onClick={() => {
                  onOpen();
                }}
                size="md"
                colorScheme="red"
                variant="ghost"
                icon={<FaTrash />}
                isRound
              />
            )}
          {UserMain?.role?.toLowerCase() === "admin" && (
            <IconButton
              ml="10px"
              // onClick={() => deleteUserHandler(user?._id)}
              onClick={() => {
                onOpenEdit();
              }}
              size="md"
              variant="ghost"
              icon={<AiFillEdit />}
              isRound
            />
          )}
        </div>
    </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to delete ?</ModalHeader>
          <ModalCloseButton />

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="red"
              onClick={() => deleteUserHandler(user?._id)}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenEdit}
        onClose={() => {
          onCloseEdit();
          setEditUsername(username);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Name and Bio</ModalHeader>
          <ModalCloseButton />
          <Card padding={"3.5"}>
            <Box border="2px solid blue">
              <Editor
                editorState={editorState}
                onEditorStateChange={(state) => {
                  // console.log("=========", state);
                  handleEditorChange(state);
                }}
                toolbar={{
                  options: ["inline", "list"],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: [
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "monospace",
                    ],
                  },
                  list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["unordered", "ordered"],
                  },
                }}
              />
            </Box>
            <FormControl mb={4}>
              <FormLabel htmlFor="usrname">Edit Name</FormLabel>
              <Input
                type="text"
                id="usrname"
                placeholder="First Name and Last Name"
                onChange={(e) => setEditUsername(e.target.value)}
                value={editUsername}
                required
              />
            </FormControl>

            <Button
              mt={4}
              width={"100%"}
              mx={"auto"}
              colorScheme="blue"
              type="submit"
              onClick={UpdateUserHandler}
            >
              Update
            </Button>
            <Button
              width={"100%"}
              mt={4}
              mx={"auto"}
              colorScheme="blue"
              onClick={() => {
                onCloseEdit();
                setEditUsername(username);
              }}
            >
              Close
            </Button>
          </Card>
        </ModalContent>
      </Modal>
    </>
  );
}
