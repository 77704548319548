import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import { HOMEPAGE, FORGOT } from "lib/routes";
import { useLogin } from "hooks/auth";
import { useForm } from "react-hook-form";
import { emailValidate, passwordValidate } from "utils/form-validate";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";

export default function Login() {
  const { login, isLoading } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("forgotPasswordData", "");
    const token = localStorage.getItem("token");
    if (token) {
      navigate(HOMEPAGE);
    }
  }, []);

  async function handleLogin(data) {
    login({
      data: { email: data.email, password: data.password },
      redirectTo: HOMEPAGE,
    });
  }

  return (
    <div className="background-wrapper">
      <Center w="100%" h="100vh">
        <Box
          mx="1"
          maxW="xl"
          p="9"
          // borderWidth="1px"
          // borderRadius="lg"
          // bgColor="white"
          // mt="28"
        >
          <Heading mb="4" mt="8" size="lg" textAlign="center">
            Log In
          </Heading>

          <form onSubmit={handleSubmit(handleLogin)}>
            <FormControl isInvalid={errors.email} py="2">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="user@email.com"
                {...register("email", emailValidate)}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password} py="2">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="password"
                {...register("password", passwordValidate)}
              />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <Link to={FORGOT}>Forgot your password?</Link>
            <Button
              mt="4"
              type="submit"
              colorScheme="blue"
              size="md"
              w="full"
              isLoading={isLoading}
              // loadingText="Logging In"
            >
              Log In
            </Button>
          </form>
        </Box>
      </Center>
    </div>
  );
}
