import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useUsers } from "hooks/users";
import User from "./User";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "services/api";
import { InfinitySpin } from "react-loader-spinner";
import { useAuth } from "hooks/auth";

export default function AllUsers() {
  // const { users, isLoading } = useUsers();
  const { user: UserMain } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userSearchResult, setUserSearchResult] = useState([]);
  const [userSearchText, setUserSearchText] = useState("");
  useEffect(() => {
    axios.get(`${baseUrl}/user/getAllUserForAdmin`).then((res) => {
      if (res.data.status) {
        setUsers(res.data.result);
        setLoading(false);
      }
    });
  }, []);

  const handleSearchUsers = (event) => {
    event.preventDefault();
    const search = event.target.value;
    console.log(search);
    setUserSearchText(search);
    if (userSearchText !== "") {
      const results = users?.filter((user) =>
        user.username.toLowerCase().includes(userSearchText.toLowerCase())
      );
      setUserSearchResult(results);
    }
  };

  if (loading)
    return (
      <Box p="2" maxW="150px" textAlign="center" mx={"auto"} mt={"12"}>
        <InfinitySpin
          width="200"
          color="#008080"
          // style={{ marginLeft: "47%", display: "block" }}
        />
      </Box>
    );

  return (
    <>
      <div className="circle-main">
        <input
          onChange={handleSearchUsers}
          className="search-input-circle"
          placeholder="Search User"
          style={{ marginTop: "30px" }}
        />
      </div>

      <SimpleGrid columns={[2, 3, 4]} spacing={[2, 3]} px="10px" py="6">
        {userSearchText ? (
          <>
            {userSearchResult?.map((user) => (
              <User
                key={user._id}
                user={user}
                UserMain={UserMain}
                setUsers={setUsers}
                users={users}
              />
            ))}
          </>
        ) : (
          <>
            {users?.map((user) => (
              <User
                key={user._id}
                user={user}
                UserMain={UserMain}
                setUsers={setUsers}
                users={users}
              />
            ))}
          </>
        )}
      </SimpleGrid>
    </>
  );
}
