import {
  Box,
  Button,
  Card,
  Flex,
  HStack,
  Heading,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Avatar as ChakraAvatar } from "@chakra-ui/react";

import axios from "axios";
import PostsList from "components/post/PostsList";
import { useAuth } from "hooks/auth";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useNavigate, useParams } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { backendUrl, baseUrl } from "services/api";
import Resizer from "react-image-file-resizer";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { PostServices } from "services/postServices";
import "./CirclePost.css";
import { PROTECTED } from "lib/routes";
const CirclePost = () => {
  const { register, handleSubmit, reset } = useForm();
  const { user, isLoading: authLoading } = useAuth();
  const toast = useToast();
  const { id } = useParams();
  const [refetch, setRefetch] = useState(0);
  const [postList, setPostList] = useState([]);
  const [imageUpload, setImageUpload] = useState("");
  const [pdfUpload, setPdfUpload] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [circleUsers, setCircleUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${baseUrl}/circlesUser/getAllUserByCircle/${id}`).then((res) => {
      if (res?.data?.status) {
        setCircleUsers(res.data?.result);
      }
    });
  }, [id]);

  const handleEditorChange = (state) => {
    setEditorState(state);
  };
  async function handleAddPost(data) {
    const content = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    if (pdfUpload) {
      const formData = new FormData();
      formData.append("pdf", pdfUpload);
      await axios
        .post(baseUrl + "/posts/uploadPdf", formData)
        .then(async (res) => {
          if (res.data.status) {
            // console.log(res.data.data);
    const d = {
              postBody: content,
      postBy: user?._id,
      likes: 0,
      comments: 0,
      circleId: id,
      postImg: imageUpload,
      postPdf: res.data.fileNamePdf,
    };
    await axios.post(baseUrl + "/posts", d).then((res) => {
      if (res.data.status) {
        toast({
          title: "Posted",
          description: res.data.message,
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
        setEditorState("");
                if (page === 1) {
        setRefetch(refetch + 1);
                } else {
                  setPage(1);
                }
        reset();
        setImageUpload("");
              }
            });
          }
        });
    } else {
      const d = {
        postBody: content,
        postBy: user?._id,
        likes: 0,
        comments: 0,
        circleId: id,
        postImg: imageUpload,
      };
      await axios.post(baseUrl + "/posts", d).then((res) => {
        if (res.data.status) {
          toast({
            title: "Posted",
            description: res.data.message,
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
          setEditorState("");
          if (page === 1) {
          setRefetch(refetch + 1);
          } else {
            setPage(1);
          }
          reset();
          setImageUpload("");
      } else {
        toast({
          title: "Failed to Post",
          description: res.data.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
  }
  }

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    // console.log(file);
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp"
    ) {
      // console.log("Hrllo");
    Resizer.imageFileResizer(
      file,
      700, // Max width
      700, // Max height
      "PNG", // Output format
      100, // Quality
      0, // Rotation
      (base64Image) => {
        // Handle the optimized image (base64)
        setImageUpload(base64Image);
      },
      "base64" // Output type
    );
    } else {
      setPdfUpload(file);
      // Do something with the base64String, such as sending it to the server
    }
  };

  async function fetchPosts() {
    let { newPost, total } = await PostServices.fetchPostByCircleId(id, page);
    if (page === 1) {
    setPostList(newPost);
    } else {
      setPostList((prev) => [...prev, ...newPost]);
    }
    setTotal(total);
  }
  useEffect(() => {
    fetchPosts();
  }, [id, refetch, page]);

  const settings = {
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <div className="circle-post">
        <div className="circle-users">
          <h3>Circle Members</h3>
          <div>
            {circleUsers?.map((cUser) => (
              <div className="circle-user-card" key={cUser?._id}>
                <ChakraAvatar
                  name={cUser?.userId?.username}
                  size={"lg"}
                  src={null || cUser?.userId?.profileImg}
                  _hover={{ cursor: "pointer", opacity: "0.8" }}
                />
                <div>
                  <p className="circle-userName">{cUser?.userId?.username}</p>
                  <button
                    onClick={() =>
                      navigate(`${PROTECTED}/profile/${cUser?.userId?._id}`)
                    }
                    className="btn-style"
                  >
                    View Profile
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="main-contain-mobile">
          <h3 className="mobile-user-heading">Circle Members</h3>
          <div className="mobile-circle-users">
            <div className="mobile-circle-user-list">
              {circleUsers?.map((cUser) => (
                <div className="circle-user-card-mobile" key={cUser?._id}>
                  <ChakraAvatar
                    name={cUser?.userId?.username}
                    size={"lg"}
                    src={null || cUser?.userId?.profileImg}
                    _hover={{ cursor: "pointer", opacity: "0.8" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p className="circle-userName">{cUser?.userId?.username}</p>
                    <button
                      onClick={() =>
                        navigate(`${PROTECTED}/profile/${cUser?.userId?._id}`)
                      }
                      className="btn-style"
                    >
                      View Profile
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
      <Card
        maxW="650px"
        mx="auto"
        p="20px"
        borderWidth="2px"
        borderColor="0,0,0,0.5"
      >
        <form onSubmit={handleSubmit(handleAddPost)}>
          <div style={{ padding: "10px" }}>
          <HStack justify="space-between">
            <Heading size="lg">New Post</Heading>
            <Button
              colorScheme="blue"
              type="submit"
              //   isLoading={authLoading || addingPost}
              loadingText="Loading"
            >
              Post
            </Button>
          </HStack>
          </div>
          <Box border="2px solid blue">
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              toolbar={{
                options: ["inline", "list"],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: [
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "monospace",
                  ],
                },
                list: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ["unordered", "ordered"],
                },
              }}
          />
          </Box>
          <Box marginTop={"30px"}>
          <input type="file" onChange={handleFileChange} />
          </Box>
        </form>
      </Card>
      <PostsList
        refetch={refetch}
        setRefetch={setRefetch}
        posts={postList}
        form={false}
        page={page}
        setPage={setPage}
        total={total}
      />
    </div>
      </div>
    </div>
  );
};

export default CirclePost;
