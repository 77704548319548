import { Box, Card, Flex, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillPlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "services/api";
import { useToast } from "@chakra-ui/react";
import { FaUserCircle } from "react-icons/fa";
import { IoIosRemoveCircle } from "react-icons/io";
import { useAuth } from "hooks/auth";

const AddChat = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [allStaff, setAllStaff] = useState([]);
  const [selected, setSelected] = useState([]);
  const { user, isLoading: userLoading } = useAuth();
  const [admins, setAdmins] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (user?.role === "admin") {
      // axios.get(`${baseUrl}/user/getAllUserForAdmin`).then((res) => {
      //   if (res.data.status) {
      //     setAllStaff(res.data.result);
      //   }
      // });
      axios.get(`${baseUrl}/user/getAllUsers`).then((res) => {
        if (res.data.status) {
          setAllStaff(res.data.result);
        }
      });
    } else {
      axios
        .get(`${baseUrl}/user/getAllCircleUsers/${user?._id}`)
        .then((res) => {
          if (res.data.status) {
            setAllStaff(res.data.result);
          }
        });
    }
  }, [user?._id, user?.role]);
  useEffect(() => {
    axios.get(`${baseUrl}/user/getAllAdmins`).then((res) => {
      if (res.data.status) {
        setAdmins(res.data.result);
      }
    });
  }, []);
  async function onSubmit(values) {
    // console.log("values", values, selected);
    if (selected.length === 0) {
      toast({
        title: "Failed to create Chat Room",
        description: "Please select atleast one staff member",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      reset();
      return;
    }
    const data = {
      roomName: values.name,
      members: [...selected, user._id],
    };
    try {
      const response = await axios.post(
        `${baseUrl}/chat-room/createChatRoom`,
        data
      );
      toast({
        title: "Chat Group created",
        description: response.data.message,
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      reset();
      setSelected([]);
    } catch (error) {
      console.log(error);
      toast({
        title: "Failed to create Chat Room",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      reset();
      return;
    }
  }

  const handleAdd = (id) => {
    setSelected([...selected, id]);
  };
  const handleRemove = (id) => {
    // console.log(id);
    const filter = selected.filter((s) => s !== id);
    setSelected(filter);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const search = event.target.value;
    // console.log(searchText);
    setSearchText(search);
    if (searchText !== "") {
      const results = [...allStaff, ...admins]?.filter((user) =>
        user.username.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchResult(results);
    }
  };

  return (
    <div>
      <Box p="2" textAlign="center" mx="auto">
        <Box p="2" maxW="700px" textAlign="left" mx="auto">
          <Flex mb={4}>
            <AiFillLeftCircle
              className="add-circle-back"
              onClick={() => navigate("/protected/chat")}
            />
            <Text mt={-1} ms={4} fontSize="3xl">
              Create Chat Room
            </Text>
          </Flex>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card mx="auto" p="10px" borderWidth="2px" borderColor="0,0,0,0.5">
              <FormControl mb={4} isInvalid={errors.name}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  id="name"
                  placeholder="Name"
                  {...register("name", {
                    required: "This is required",
                    minLength: {
                      value: 1,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
            </Card>

            <Card
              mx="auto"
              p="10px"
              borderWidth="2px"
              borderColor="0,0,0,0.5"
              mt={8}
            >
              <FormLabel>Add Users</FormLabel>
              <FormControl mb={4} isInvalid={errors.dateOfBirth}>
                <Input
                  type="text"
                  id="phone"
                  placeholder="Search Users"
                  onChange={handleSearch}
                />
              </FormControl>
              <div mb={4} className="users-container">
                {
                  <>
                    {user?.role === "admin" ? (
                      <>
                        {searchText ? (
                          <>
                            {searchResult.map((user) => (
                              <div
                                className="add-circle-card-style"
                                key={user._id}
                              >
                                <div className="name-circle">
                                  <FaUserCircle className="user-circle" />{" "}
                                  <h4 style={{ marginLeft: "10px" }}>
                                    <b>{user?.username}</b>
                                  </h4>
                                </div>
                                {selected.includes(user?._id) ? (
                                  <IoIosRemoveCircle
                                    onClick={() => handleRemove(user?._id)}
                                    className="remove-circle-icon"
                                  />
                                ) : (
                                  <AiFillPlusCircle
                                    onClick={() => handleAdd(user?._id)}
                                    className="add-circle-icon"
                                  />
                                )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            {allStaff.map((user) => (
                              <div
                                className="add-circle-card-style"
                                key={user._id}
                              >
                                <div className="name-circle">
                                  <FaUserCircle className="user-circle" />{" "}
                                  <h4 style={{ marginLeft: "10px" }}>
                                    <b>{user?.username}</b>
                                  </h4>
                                </div>
                                {selected.includes(user?._id) ? (
                                  <IoIosRemoveCircle
                                    onClick={() => handleRemove(user?._id)}
                                    className="remove-circle-icon"
                                  />
                                ) : (
                                  <AiFillPlusCircle
                                    onClick={() => handleAdd(user?._id)}
                                    className="add-circle-icon"
                                  />
                                )}
                              </div>
                            ))}
                            {admins.map((user) => (
                              <div
                                className="add-circle-card-style"
                                key={user._id}
                              >
                                <div className="name-circle">
                                  <FaUserCircle className="user-circle" />{" "}
                                  <h4 style={{ marginLeft: "10px" }}>
                                    <b>{user?.username}</b>
                                  </h4>
                                </div>
                                {selected.includes(user?._id) ? (
                                  <IoIosRemoveCircle
                                    onClick={() => handleRemove(user?._id)}
                                    className="remove-circle-icon"
                                  />
                                ) : (
                                  <AiFillPlusCircle
                                    onClick={() => handleAdd(user?._id)}
                                    className="add-circle-icon"
                                  />
                                )}
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {searchText ? (
                          <>
                          {searchResult.map((user) => (
                          <div className="add-circle-card-style" key={user._id}>
                            <div className="name-circle">
                              <FaUserCircle className="user-circle" />{" "}
                              <h4 style={{ marginLeft: "10px" }}>
                                <b>{user?.username}</b>
                              </h4>
                            </div>
                            {selected.includes(user?._id) ? (
                              <IoIosRemoveCircle
                                onClick={() => handleRemove(user?._id)}
                                className="remove-circle-icon"
                              />
                            ) : (
                              <AiFillPlusCircle
                                onClick={() => handleAdd(user?._id)}
                                className="add-circle-icon"
                              />
                            )}
                          </div>
                        ))}
                        </>
                        ) : (
                          <>
                            {allStaff.map((user) => (
                              <div
                                className="add-circle-card-style"
                                key={user._id}
                              >
                                <div className="name-circle">
                                  <FaUserCircle className="user-circle" />{" "}
                                  <h4 style={{ marginLeft: "10px" }}>
                                    <b>{user?.username}</b>
                                  </h4>
                                </div>
                                {selected.includes(user?._id) ? (
                                  <IoIosRemoveCircle
                                    onClick={() => handleRemove(user?._id)}
                                    className="remove-circle-icon"
                                  />
                                ) : (
                                  <AiFillPlusCircle
                                    onClick={() => handleAdd(user?._id)}
                                    className="add-circle-icon"
                                  />
                                )}
                              </div>
                            ))}
                            {admins.map((user) => (
                              <div
                                className="add-circle-card-style"
                                key={user._id}
                              >
                                <div className="name-circle">
                                  <FaUserCircle className="user-circle" />{" "}
                                  <h4 style={{ marginLeft: "10px" }}>
                                    <b>{user?.username}</b>
                                  </h4>
                                </div>
                                {selected.includes(user?._id) ? (
                                  <IoIosRemoveCircle
                                    onClick={() => handleRemove(user?._id)}
                                    className="remove-circle-icon"
                                  />
                                ) : (
                                  <AiFillPlusCircle
                                    onClick={() => handleAdd(user?._id)}
                                    className="add-circle-icon"
                                  />
                                )}
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </>
                }
              </div>
              <Button
                mt={4}
                mx={"auto"}
                colorScheme="blue"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </Card>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default AddChat;