import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useAuth } from "hooks/auth";
import { useUpdateAvatar } from "hooks/users";
import Avatar from "./Avatar";
import { useState } from "react";
import { Avatar as ChakraAvatar } from "@chakra-ui/react";
import { PROTECTED } from "lib/routes";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "services/api";
import Resizer from "react-image-file-resizer";

export default function EditProfile({
  isOpen,
  onClose,
  refetch,
  setRefetch,
  user,
  getUser,
}) {
  const [imageUpload, setImageUpload] = useState("");
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    Resizer.imageFileResizer(
      file,
      700, // Max width
      700, // Max height
      "JPEG", // Output format
      100, // Quality
      0, // Rotation
      (base64Image) => {
        // Handle the optimized image (base64)
        setImageUpload(base64Image);
      },
      "base64" // Output type
    );
  };
  // if (authLoading) return "Loading...";
  const updateAvatar = async (id) => {
    // /
    if (imageUpload) {
      setLoading(true);
      await axios
        .patch(baseUrl + `/user/updateUserInfoById/${user?._id}`, {
          profileImg: imageUpload,
        })
        .then((res) => {
          if (res.data.status) {
            toast({
              title: "Profile Image Changed",
              description: res.data.message,
              status: "success",
              isClosable: true,
              position: "top",
              duration: 5000,
            });
            setRefetch(refetch + 1);
            setLoading(false);
            setImageUpload("");
            onClose();
            getUser();
          } else {
            toast({
              title: "Failed to change the Profile Image",
              description: res.data.message,
              status: "error",
              isClosable: true,
              position: "top",
              duration: 5000,
            });
          }
          setLoading(false);
        });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {loading ? (
        <Text textAlign={"center"} mt={"4"}>
          Updating....
        </Text>
      ) : (
        <ModalContent>
          <ModalHeader>Edit profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack spacing="5">
              {imageUpload ? (
                <>
                  <ChakraAvatar
                    as={Link}
                    to={`${PROTECTED}/profile/${user?._id}`}
                    name={user?.username}
                    size="xl"
                    src={imageUpload}
                    _hover={{ cursor: "pointer", opacity: "0.8" }}
                  />
                </>
              ) : (
                <Avatar user={user} />
              )}
              <FormControl py="4">
                <FormLabel htmlFor="picture">Change avatar</FormLabel>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </FormControl>
            </HStack>
            <Button
              loadingText="Uploading"
              w="full"
              my="6"
              colorScheme="orange"
              onClick={updateAvatar}
              // isLoading={fileLoading}
            >
              Save
            </Button>
          </ModalBody>
        </ModalContent>
      )}
    </Modal>
  );
}
