import { Card, Box, Text, Image } from "@chakra-ui/react";
import Header from "./Header";
import Actions from "./Actions";
import { baseUrl } from "services/api";

export default function Post({ post, refetch, setRefetch, from }) {
  const { postBody } = post;
  //console.log(post, "here id")

  const handleDownload = async (fileName) => {
    try {
      const response = await fetch(`${baseUrl}/posts/downloadPdf/${fileName}`);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "example.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const parseURLs = (content) => {
    // console.log("content", content);
    const urlRegex = /((?:https?:\/\/|www\.)[^\s]+)/g;
    const parsedContent = content.replace(urlRegex, (match) => {
      const decodedURL = decodeURIComponent(match);
      const url = decodedURL.split("<")[0];
      console.log("decodeURL", url);
      if (decodedURL.startsWith("http")) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      } else {
        return `<a href="http://${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }
    });
    return parsedContent;
  };

  return (
    <Box p="2" maxW="700px" textAlign="left">
      <Card mx="auto" p="20px" borderWidth="2px" borderColor="0,0,0,0.5">
        <Header post={post} />

        <Box p="2" minH="100px">
          <Text wordBreak="break-word" fontSize="md" padding={2}>
            {/* {post?.carePostBody} */}
            <div
              dangerouslySetInnerHTML={{ __html: parseURLs(post?.content) }}
            />
          </Text>
          {post?.postPdf && (
            <Text
              cursor={"pointer"}
              fontWeight={"bolder"}
              color={"blue.600"}
              onClick={() => handleDownload(post?.postPdf)}
            >
              View Pdf
            </Text>
          )}
        </Box>
        {post?.postImg && (
          <Box width={"100%"}>
            <Image width={"100%"} src={post?.postImg} alt="Image" />
          </Box>
        )}
        <Actions
          refetch={refetch}
          setRefetch={setRefetch}
          post={post}
          from={from}
        />
      </Card>
    </Box>
  );
}
