import { Box, Flex, Text } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";
import { useUser } from "hooks/users";
import { format, formatDistanceToNow } from "date-fns";
import UsernameButton from "components/profile/UsernameButton";
import { useAuth } from "hooks/auth";

export default function Header({ post, from }) {
  // const { user, isLoading } = useUser(uid);
  
  // if (isLoading) return "Loading...";
   const { user, isLoading: userLoading } = useAuth();
  return (
    <Flex
      borderBottom="2px solid"
      borderColor="blue.100"
      py="3"
      bg="gray.50"
      justifyContent="space-between"
      alignItems={"start"}
    >
      <Flex alignItems="center" px="3">
      <Avatar user={post?.postBy} size="md" />

      <Box ml="4">
        <UsernameButton user={post?.postBy} />
        <Text fontSize="sm" color="gray.500">
          {post?.createdAt && (
              <>
                {from === "exportPdf" ? (
                  <>
                    {format(
                      new Date(post?.createdAt),
                      "yyyy-MM-dd' at 'HH:mm:ss"
                    )}
                  </>
                ) : (
            <>{formatDistanceToNow(new Date(post?.createdAt))} ago</>
          )}
              </>
            )}
        </Text>
      </Box>
      </Flex>
      <Box marginRight={"3"} fontWeight={"bolder"} color={"teal.400"}>
        {post?.circleId?.firstName} {post?.circleId?.lastName}
      </Box>
    </Flex>
  );
}
