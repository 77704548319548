import {
  Box,
  Button,
  Card,
  Input,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./chat.css";
import fakeImg from "../../images/fakeImg";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineDotsVertical, HiOutlineUserRemove } from "react-icons/hi";
import { BsPlusCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "services/api";
import { useAuth } from "hooks/auth";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { InfinitySpin } from "react-loader-spinner";
import { AiFillPlusCircle, AiTwotoneDelete } from "react-icons/ai";

const Chats = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenSecond,
    onOpen: onOpenSecond,
    onClose: onCloseSecond,
  } = useDisclosure();

  const [refetch, setRefetch] = useState(0);
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [availableMembers, setavailableMembers] = useState([]);
  const [filteredAvailableMembers, setfilteredAvailableMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [rooms, setRooms] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [showChat, setShowChat] = useState("chat");
  const [roomAdmins, setRoomAdmins] = useState([]);
  const [logAdmins, setLogAdmins] = useState([]);
  const { user, isLoading: userLoading } = useAuth();
  const toast = useToast();

  //   console.log(user, userLoading);

  useEffect(() => {
    if (!userLoading) {
      if (user?.role.toLowerCase() === "admin") {
        // `${baseUrl}/chat-room/createChatRoom`
        // console.log("admin");
        axios
          .post(`${baseUrl}/chat-room/getAllChatRooms`)
          .then((data) => {
            // console.log(data.data.data);
            const rooms = [];
            const logs = [];
            for (const i of data?.data?.data) {
              if (i?.members?.includes(user?._id.toString())) {
                rooms.push(i);
              } else {
                logs.push(i);
              }
            }
            setRoomAdmins(rooms);
            setLogAdmins(logs);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      } else {
        const data = {
          userId: user._id,
        };
        axios
          .post(`${baseUrl}/chat-room/getUserChatRooms`, data)
          .then((data) => {
            setRooms(data.data.data);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [userLoading]);

  const deleteChat = async (id) => {
    setSelectedId("");

    await axios
      .delete(baseUrl + `/chat-room/deleteChatRoom/${id}`)
      .then((res) => {
        if (res.data.success) {
          toast({
            title: "Chat Group Deleted",
            description: res.data.message,
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
          // setRefetch(refetch + 1);
          const newData = rooms.filter((room) => {
            return room._id !== id;
          });
          setRooms(newData);
        } else {
          toast({
            title: "Failed to delete Chat Group",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
      });
  };

  const removeMember = async (member) => {
    try {
      // console.log("remove member");
      const response = await axios.post(`${baseUrl}/chat-room/removeMember`, {
        userId: member?._id,
        chatId: selectedRoom,
      });
      // console.log("response.data", response.data);
      setMembers((prevMembers) =>
        prevMembers.filter((item) => item?._id !== member._id)
      );
      toast({
        title: "User Removed",
        description: response?.data?.message,
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } catch (error) {
      toast({
        title: "Failed to delete Chat Group",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
  };

  const handleMembers = async (Id) => {
    setSelectedId("");
    await axios
      .get(baseUrl + `/chat-room/getAllMembersByRoom/${Id}`)
      .then((res) => {
        if (res) {
          setSelectedRoom(Id);
          // console.log("====>>>", res);
          setMembers(res.data.data.members);
          onOpen();
          onCloseSecond();
        } else {
          toast({
            title: "Failed to load",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
      });
  };

  const openAddMember = async (chatId) => {
    try {
      setSelectedId("");
      const response = await axios.post(
        `${baseUrl}/chat-room/showAvailableUsers`,
        { chatId }
      );
      setSelectedRoom(chatId);
      setavailableMembers(response.data?.data);
      onClose();
      onOpenSecond();
    } catch (error) {
      toast({
        title: "Failed to delete Chat Group",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
  };

  const addMemeber = async (member) => {
    try {
      const response = await axios.post(`${baseUrl}/chat-room/addMember`, {
        userId: member?._id,
        chatId: selectedRoom,
      });
      onCloseSecond();
      toast({
        title: "User Added",
        description: response?.data?.message,
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } catch (error) {
      toast({
        title: "Failed to delete Chat Group",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
  };

  const onSearchTermChange = (e) => {
    setSearchTerm(e.target.value);

    const filteredMembers = availableMembers.filter((member) =>
      member?.username
        ?.toLowerCase()
        ?.trim()
        ?.includes(e?.target?.value?.toLowerCase()?.trim())
    );

    setfilteredAvailableMembers(filteredMembers);
  };

  // console.log("rooms", rooms);
  if (loading)
    return (
      <Box p="2" maxW="150px" textAlign="center" mx={"auto"} mt={"12"}>
        <InfinitySpin
          width="200"
          color="#008080"
          // style={{ marginLeft: "47%", display: "block" }}
        />
      </Box>
    );

  return (
    <Box p="2" maxW="700px" textAlign="left" mx={"auto"} mt={"12"} h={8}>
      <Card
        mx="auto"
        p="10px"
        borderWidth="2px"
        borderColor="0,0,0,0.5"
        style={{ position: "relative" }}
        pt={"12"}
      >
        <BsPlusCircleFill
          className="add-circle"
          onClick={() => navigate("/protected/addchat")}
        />
        <div className="circle-main">
          {rooms.length === 0 &&
            roomAdmins.length === 0 &&
            logAdmins.length === 0 && (
              <>
                {user?.role === "admin" ? (
                  <Text textAlign={"center"}>
                    There is no chat group available
                  </Text>
                ) : (
                  <Text textAlign={"center"}>
                    Please create a chat group to begin chatting.
                  </Text>
                )}
              </>
            )}
        </div>
        <div className="card-style">
          {(rooms.length !== 0 ||
            roomAdmins.length !== 0 ||
            logAdmins.length !== 0) && (
            <Text
              textAlign={"center"}
              fontSize={"4xl"}
              fontWeight={"bold"}
              marginBottom={"6"}
            >
              Chat Groups
            </Text>
          )}
          {user?.role === "admin" && (
            <div className="admin-chat-toggle">
              <p
                onClick={() => setShowChat("chat")}
                className={
                  showChat === "chat"
                    ? "admin-chat-toggle-p-active"
                    : "admin-chat-toggle-p"
                }
              >
                Chats
              </p>
              <p
                onClick={() => setShowChat("log")}
                className={
                  showChat === "log"
                    ? "admin-chat-toggle-p-active"
                    : "admin-chat-toggle-p"
                }
              >
                Logs
              </p>
            </div>
          )}
          {
            <>
              {user?.role === "admin" ? (
                <>
                  {showChat === "chat" && (
                    <>
                      {roomAdmins?.map((room) => (
                        <div className="circle-card-style" key={room._id}>
                          <div
                            className="name-circle"
                            onClick={() =>
                              navigate(`/protected/chat/${room?._id}`)
                            }
                          >
                            <h4 style={{ padding: "16px 0 0 10px" }}>
                              Chat Group: <b>{room.name}</b>
                            </h4>
                          </div>
                          {user?.role?.toLowerCase() === "admin" && (
                            <HiOutlineDotsVertical
                              onClick={() => {
                                if (selectedId === room._id) {
                                  setSelectedId("");
                                } else {
                                  setSelectedId(room?._id);
                                }
                              }}
                              className="circle-three-dots"
                            />
                          )}
                          {room?._id === selectedId && (
                            <div className="three-dot-menu-style-chat">
                              <p onClick={() => handleMembers(room?._id)}>
                                View Group Members
                              </p>
                              <p onClick={() => deleteChat(room?._id)}>
                                Delete Group
                              </p>
                              <p onClick={() => openAddMember(room?._id)}>
                                Add Member
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}

                  {showChat === "log" && (
                    <>
                      {logAdmins?.map((room) => (
                        <div className="circle-card-style" key={room._id}>
                          <div
                            className="name-circle"
                            onClick={() =>
                              navigate(`/protected/chat-log/${room?._id}`)
                            }
                          >
                            <h4 style={{ padding: "16px 0 0 10px" }}>
                              Chat Group: <b>{room.name}</b>
                            </h4>
                          </div>
                          {user?.role?.toLowerCase() === "admin" && (
                            <HiOutlineDotsVertical
                              onClick={() => {
                                if (selectedId === room._id) {
                                  setSelectedId("");
                                } else {
                                  setSelectedId(room?._id);
                                }
                              }}
                              className="circle-three-dots"
                            />
                          )}
                          {room?._id === selectedId && (
                            <div className="three-dot-menu-style-chat">
                              <p onClick={() => handleMembers(room?._id)}>
                                View Group Members
                              </p>
                              <p onClick={() => deleteChat(room?._id)}>
                                Delete Group
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  {rooms.map((room) => (
                    <div className="circle-card-style" key={room._id}>
                      <div
                        className="name-circle"
                        onClick={() => navigate(`/protected/chat/${room?._id}`)}
                      >
                        <h4 style={{ padding: "16px 0 0 10px" }}>
                          Chat Group: <b>{room.name}</b>
                        </h4>
                      </div>
                      {user?.role?.toLowerCase() === "admin" && (
                        <HiOutlineDotsVertical
                          onClick={() => {
                            if (selectedId === room._id) {
                              setSelectedId("");
                            } else {
                              setSelectedId(room?._id);
                            }
                          }}
                          className="circle-three-dots"
                        />
                      )}
                      {room?._id === selectedId && (
                        <div className="three-dot-menu-style-chat">
                          <p onClick={() => handleMembers(room?._id)}>
                            View Group Members
                          </p>
                          <p onClick={() => deleteChat(room?._id)}>
                            Delete Group
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </>
          }
        </div>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Circle Members</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {members?.map((member) => (
              <div
                className="add-circle-card-style"
                key={member._id}
                // style={{ width: "70%" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  key={member._id}
                >
                <div className="name-circle">
                  {member?.profileImg ? (
                    <img
                      src={fakeImg}
                      height={50}
                      width={50}
                      alt="prfile imgh"
                    />
                  ) : (
                    <FaUserCircle className="user-circle" />
                  )}
                  <h4 style={{ marginLeft: "10px" }}>
                    <b>{member?.username}</b>
                  </h4>
                </div>
                  {user?._id !== member?._id && user?.role === "admin" && (
                    // member.role !== "admin" &&
                    <div
                      style={{
                        marginTop: "10px",
                        color: "red",
                        cursor: "pointer",
                        marginRight: "20px",
                        fontSize: "20px",
                      }}
                      title="Remove user"
                    >
                      <AiTwotoneDelete
                        onClick={() => {
                          removeMember(member);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenSecond} onClose={onCloseSecond}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Circle Members</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Search Members"
              border="2px"
              className="add-circle-card-style"
              style={{ width: "96%" }}
              value={searchTerm}
              onChange={(e) => onSearchTermChange(e)}
            />
            {!searchTerm
              ? availableMembers?.map((member) => (
              <div
                className="add-circle-card-style"
                key={member._id}
                // style={{ width: "70%" }}
              >
                <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                  key={member._id}
                >
                  <div className="name-circle">
                    {member?.profileImg ? (
                      <img
                        src={fakeImg}
                        height={50}
                        width={50}
                        alt="prfile imgh"
                      />
                    ) : (
                      <FaUserCircle className="user-circle" />
                    )}
                    <h4 style={{ marginLeft: "10px" }}>
                      <b>{member?.username}</b>
                    </h4>
                  </div>
                  {user?._id !== member?._id && user?.role === "admin" && (
                    // member.role !== "admin" &&
                        <div title="Add Member">
                          <AiFillPlusCircle
                            className="add-circle-icon"
                            style={{
                              marginRight: "10px",
                              marginTop: "2.5px",
                            }}
                            onClick={() => {
                              addMemeber(member);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))
              : filteredAvailableMembers?.map((member) => (
                    <div
                    className="add-circle-card-style"
                    key={member._id}
                    // style={{ width: "70%" }}
                    >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key={member._id}
                    >
                      <div className="name-circle">
                        {member?.profileImg ? (
                          <img
                            src={fakeImg}
                            height={50}
                            width={50}
                            alt="prfile imgh"
                          />
                        ) : (
                          <FaUserCircle className="user-circle" />
                        )}
                        <h4 style={{ marginLeft: "10px" }}>
                          <b>{member?.username}</b>
                        </h4>
                      </div>
                      {user?._id !== member?._id && user?.role === "admin" && (
                        // member.role !== "admin" &&
                        <div title="Add Member">
                      <AiFillPlusCircle
                        className="add-circle-icon"
                        style={{
                          marginRight: "10px",
                          marginTop: "2.5px",
                        }}
                        onClick={() => {
                          addMemeber(member);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onCloseSecond}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Chats;
