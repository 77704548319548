const inviteTemplatehtml = (username, url) => {
  return `
  <!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <title>Invitation to Sign In</title>
    <style>
        body {
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
            font-family: Arial, sans-serif;
        }
        .container {
            max-width: 600px;
            margin: 20px auto;
            background-color: #ffffff;
            border: 1px solid #dddddd;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 20px;
        }

    </style>
</head>
<body>
    <div class="container">
        <h1>Welcome To ExcellCare Connect, ${username}!</h1>
        <p>We are just reaching out with some information on our Family Portal, ExcellConnect, for you to view your loved one’s care circle.</p>
        <p>Your login information is:</p>
        <p>Username: ***FILL IN***</p>
        <p>Password: ***FILL IN***</p>
        <p>Your invite link is: </p>
        <a href="${url}"> <b> ${url}</b></a>
        <p>Once you have logged in, you are able to change your password by clicking "forgot password” on the login page, where you will get a verification code sent to your email. From there, you are able to change your password.</p>
        <p>To tell you a little bit about our Family Portal, ExcellConnect; Our partner IT company Excell IT has worked diligently alongside us to create a secure online community for our Client's families and our PSW support team to be connected. We use ExcellConnect to help connect our Client's families and caregivers to our Client's care. You will see postings on ExcellConnect of day-to-day activities or fun pictures of your loved one. This, as well as the "chat" feature, allows us all to stay connected in order to provide the best care and peace of mind.</p>
        <p>Thanks,</p>
        <p>ExcellCare Connect Team</p> 
    </div>
</body>
</html>
`;
};

export default inviteTemplatehtml;
