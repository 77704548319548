import { LOGIN } from "lib/routes";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth";
import Navbar from "components/layout/Navbar";
import Sidebar from "components/layout/Sidebar";
import { Box, Flex } from "@chakra-ui/react";
import { InfinitySpin } from "react-loader-spinner";

export default function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();

  useEffect(() => {
    if (!isLoading && pathname.startsWith("/protected") && !user) {
      navigate(LOGIN);
    }
  }, [pathname, user, isLoading]);
  // console.log(user);
  if (isLoading) return <Box p="2" maxW="150px" textAlign="center" mx={"auto"} mt={"12"}>
  <InfinitySpin
    width="200"
    color="#008080"
    // style={{ marginLeft: "47%", display: "block" }}
  />
</Box>;

  return (
    <>
      <div>
      <Navbar />
      <Flex style={{overflowY: "hidden",minHeight: "100vh", maxHeight: "100vh"}} pt="16" pb="12" mx="auto" w="full" maxW="100%">
        <Sidebar />
        <Box style={{overflowY: "scroll"}} w="100%">
          <Outlet />
        </Box>
      </Flex>
      </div>
    </>
  );
}
