import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useToast,
} from "@chakra-ui/react";
import { LOGIN, RESET } from "lib/routes";
import { useForm } from "react-hook-form";
import { emailValidate } from "utils/form-validate";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "services/api";

const Forgot = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  async function handleForgotPassword(data) {
    try {
      // console.log(data);
      const body = {
        email: data.email,
      };
      const response = await axios.post(`${baseUrl}/user/forgotPassword`, body);

      localStorage.setItem(
        "forgotPasswordData",
        JSON.stringify({
          email: response.data.data.email,
          userId: response.data.data.userId,
        })
      );
      toast({
        title: "Forgot password",
        description: response.data.message,
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      navigate(RESET);
    } catch (error) {
      toast({
        title: "Failed to forgot password",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
  }

  return (
    <>
      <Center w="100%" h="100vh">
        <Box mx="1" maxW="xl" p="9" borderWidth="1px" borderRadius="lg">
          <Heading mb="4" size="lg" textAlign="center">
            Forgot Password
          </Heading>

          <form onSubmit={handleSubmit(handleForgotPassword)}>
            <FormControl isInvalid={errors.email} py="2">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="user@email.com"
                {...register("email", emailValidate)}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>

            <Link to={LOGIN}>Want to login?</Link>
            <Button mt="4" type="submit" colorScheme="blue" size="md" w="full">
              Forgot Password
            </Button>
          </form>
        </Box>
      </Center>
    </>
  );
};

export default Forgot;
