import { Box, Card, Flex, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillPlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "services/api";
import { useToast } from "@chakra-ui/react";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoIosRemoveCircle } from "react-icons/io";
const AddCareManuals = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [admins, setAdmins] = useState([]);
  const [currentAdmin, setCurrentAdmin] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  // console.log(currentAdmin);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  useEffect(() => {
    axios.get(`${baseUrl}/user/getAllAdmins`).then((res) => {
      if (res.data.status) {
        setAdmins(res.data.result);
      }
    });
  }, []);

  useEffect(() => {
    axios.get(`${baseUrl}/user/getAllUsers`).then((res) => {
      if (res.data.status) {
        setAllUsers(res.data.result);
      }
    });
  }, []);

  // console.log(allUsers);
  async function onSubmit(values) {
    const data = {
      ...values,
      careManualAdmin: currentAdmin,
    };
    await axios.post(baseUrl + "/care-manuals", data).then(async (res) => {
      if (res.data.status) {
        await axios
          .post(baseUrl + "/careManualsUser", {
            careId: res?.data?.result?._id,
            userIds: selected,
          })
          .then((res) => {
            if (res.data.status) {
              toast({
                title: "Care Manual created",
                description: res.data.message,
                status: "success",
                isClosable: true,
                position: "top",
                duration: 5000,
              });
              reset();
              setSelected([]);
            }
          });
      } else {
        toast({
          title: "Failed to create Care Manuals",
          description: res.data.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
  }

  const handleSearch = (event) => {
    event.preventDefault();
    const search = event.target.value;
    // console.log(searchText);
    setSearchText(search);
    if (searchText !== "") {
      const results = allUsers?.filter((user) =>
        user.username.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchResult(results);
    }
  };

  const handleAdd = (id) => {
    setSelected([...selected, id]);
  };
  const handleRemove = (id) => {
    // console.log(id);
    const filter = selected.filter((s) => s !== id);
    setSelected(filter);
  };
  // console.log(selected);
  return (
    <div>
      <Box p="2" textAlign="center" mx="auto">
        <Box p="2" maxW="700px" textAlign="left" mx="auto">
          <Flex mb={4}>
            <AiFillLeftCircle
              className="add-circle-back"
              onClick={() => navigate("/protected/caremanuals")}
            />
            <Text mt={-1} ms={4} fontSize="3xl">
              Add Client Care Manuals
            </Text>
          </Flex>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card mx="auto" p="10px" borderWidth="2px" borderColor="0,0,0,0.5">
              <p style={{ fontSize: "14px", marginBottom: "20px" }}>
                Who is this Care Manuals for?
              </p>
              <FormControl mb={4} isInvalid={errors.firstName}>
                <FormLabel htmlFor="firstName">First Name</FormLabel>
                <Input
                  id="firstName"
                  placeholder="First Name"
                  {...register("firstName", {
                    required: "This is required",
                    minLength: {
                      value: 1,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.firstName && errors.firstName.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mb={4} isInvalid={errors.lastName}>
                <FormLabel htmlFor="lastName">Last Name</FormLabel>
                <Input
                  id="lastName"
                  placeholder="Last Name"
                  {...register("lastName", {
                    required: "This is required",
                    minLength: {
                      value: 1,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.lastName && errors.lastName.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mb={4} isInvalid={errors.dateOfBirth}>
                <FormLabel htmlFor="lastName">Date Of Birth</FormLabel>
                <Input
                  type="date"
                  id="dateOfBirth"
                  placeholder="Date Of Birth"
                  {...register("dateOfBirth", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.dateOfBirth && errors.dateOfBirth.message}
                </FormErrorMessage>
              </FormControl>
            </Card>
            <Card
              mx="auto"
              p="10px"
              borderWidth="2px"
              borderColor="0,0,0,0.5"
              mt={8}
            >
              <p style={{ fontSize: "14px", marginBottom: "20px" }}>
                Contact Details
              </p>
              <FormControl mb={4} isInvalid={errors.email}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  type="email"
                  id="email"
                  placeholder="Email Address"
                  {...register("email", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl mb={4} isInvalid={errors.phone}>
                <FormLabel htmlFor="phone">Mobile Phone Number</FormLabel>
                <Input
                  type="text"
                  id="phone"
                  placeholder="Mobile Phone Number"
                  {...register("phone", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.phone && errors.phone.message}
                </FormErrorMessage>
              </FormControl>
            </Card>

            <Card
              mx="auto"
              p="10px"
              borderWidth="2px"
              borderColor="0,0,0,0.5"
              mt={8}
            >
              <p style={{ fontSize: "14px", marginBottom: "20px" }}>
                Manage Access
              </p>
              <FormControl mb={4}>
                <FormLabel>Admin</FormLabel>
                <Select
                  onChange={(e) => setCurrentAdmin(e.target.value)}
                  placeholder="Select Admin"
                >
                  {admins.map((a) => (
                    <option value={a._id}>{a.username}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mb={4} isInvalid={errors.dateOfBirth}>
                <FormLabel htmlFor="phone">Add Users</FormLabel>
                <Input
                  type="text"
                  id="phone"
                  placeholder="Search Users"
                  onChange={handleSearch}
                />
              </FormControl>
              <div mb={4} className="users-container">
                {searchText ? (
                  <>
                    {searchResult?.map((user) => (
                      <div className="add-circle-card-style">
                        <div className="name-circle">
                          {/* <img src={fakeImg} height={50} width={50} alt="prfile imgh"/>  */}
                          <FaUserCircle className="user-circle" />{" "}
                          <h4 style={{ marginLeft: "10px" }}>
                            <b>{user?.username}</b>
                          </h4>
                        </div>
                        {selected.includes(user?._id) ? (
                          <IoIosRemoveCircle
                            onClick={() => handleRemove(user?._id)}
                            className="remove-circle-icon"
                          />
                        ) : (
                          <AiFillPlusCircle
                            onClick={() => handleAdd(user?._id)}
                            className="add-circle-icon"
                          />
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {allUsers.map((user) => (
                      <div className="add-circle-card-style">
                        <div className="name-circle">
                          {/* <img src={fakeImg} height={50} width={50} alt="prfile imgh"/>  */}
                          <FaUserCircle className="user-circle" />{" "}
                          <h4 style={{ marginLeft: "10px" }}>
                            <b>{user?.username}</b>
                          </h4>
                        </div>
                        {selected.includes(user?._id) ? (
                          <IoIosRemoveCircle
                            onClick={() => handleRemove(user?._id)}
                            className="remove-circle-icon"
                          />
                        ) : (
                          <AiFillPlusCircle
                            onClick={() => handleAdd(user?._id)}
                            className="add-circle-icon"
                          />
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
              <Button
                mt={4}
                mx={"auto"}
                colorScheme="blue"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </Card>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default AddCareManuals;
