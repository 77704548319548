import {
  Box,
  Button,
  Card,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useAuth } from "hooks/auth";
import { useEffect, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "services/api";
import fakeImg from "../../images/fakeImg";
import "./circle.css";

import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoIosRemoveCircle } from "react-icons/io";
import { InfinitySpin } from "react-loader-spinner";
import { usePDF } from "react-to-pdf";
import { PostServices } from "services/postServices";
const Circles = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenSecond,
    onOpen: onOpenSecond,
    onClose: onCloseSecond,
  } = useDisclosure();
  const {
    isOpen: isOpenThird,
    onOpen: onOpenThird,
    onClose: onCloseThird,
  } = useDisclosure();
  const [loading, setLoading] = useState(true);

  const [selectedCircleId, setSelectedCircleId] = useState(null);

  const [refetch, setRefetch] = useState(0);
  const [circles, setCircles] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [userSearchResult, setUserSearchResult] = useState([]);
  const [userSearchText, setUserSearchText] = useState("");
  const { user, isLoading: userLoading } = useAuth();
  const [circlePostLoading, setCirclePostLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const toast = useToast();
  const [members, setMembers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [updateCircle, setUpdateCircle] = useState([]);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const { toPDF, targetRef } = usePDF({
    filename: `Circle-${downloadPdf?.firstName} ${downloadPdf.lastName}.pdf`,
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const [admins, setAdmins] = useState([]);
  const [posts, setPosts] = useState([]);
  const [currentAdmin, setCurrentAdmin] = useState("");
  const [imageUpload, setImageUpload] = useState("");
  const [circleFirstName, setCircleFirstName] = useState("");
  const [circleSecondName, setCircleSecondName] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [circleUsers, setCircleUsers] = useState([]);
  const [downloadId, setDownloadId] = useState(false);
  async function fetchPosts() {
    axios
      .get(`${baseUrl}/posts/getPostByCircleIdDownload/${downloadId}`)
      .then((res) => {
        if (res?.data?.status) {
          const newPosts = res?.data?.result?.map((p) =>
            PostServices.processPostObj(p)
          );
          setPosts(newPosts);
          setCirclePostLoading(false);
        } else {
          setCirclePostLoading(false);
        }
      });
  }
  useEffect(() => {
    if (downloadId) {
      try {
        fetchPosts();
      } catch (error) {
        setCirclePostLoading(false);
      }
    }
  }, [downloadId]);

  useEffect(() => {
    try {
      axios
        .get(`${baseUrl}/circlesUser/getAllUserByCircle/${downloadId}`)
        .then((res) => {
          if (res?.data?.status) {
            setCircleUsers(res.data?.result);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  }, [downloadId]);

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    Resizer.imageFileResizer(
      file,
      700, // Max width
      700, // Max height
      "JPEG", // Output format
      100, // Quality
      0, // Rotation
      (base64Image) => {
        // Handle the optimized image (base64)
        setImageUpload(base64Image);
      },
      "base64" // Output type
    );
  };
  useEffect(() => {
    axios.get(`${baseUrl}/user/getAllUsers`).then((res) => {
      if (res.data.status) {
        setAllUsers(res.data.result);
      }
    });
  }, [downloading]);

  useEffect(() => {
    axios.get(`${baseUrl}/user/getAllAdmins`).then((res) => {
      if (res.data.status) {
        setAdmins(res.data.result);
      }
    });
  }, []);

  useEffect(() => {
    if (user?.role.toLowerCase() === "admin") {
      axios.get(baseUrl + "/circles").then((res) => {
        if (res.data.status) {
          setCircles(res.data.result);
          setLoading(false);
        }
      });
    } else {
      axios
        .get(baseUrl + "/circlesUser/getCirclesByUserId/" + user?._id)
        .then((res) => {
          if (res.data.status) {
            const filter = res.data.result.map((r) => r.circleId);
            setCircles(filter);
            setLoading(false);
          }
        });
    }
  }, [user?._id, user?.role, refetch]);
  // console.log(circles);

  const handleSearch = (event) => {
    event.preventDefault();
    const search = event.target.value;
    // console.log(searchText);
    setSearchText(search);
    if (searchText !== "") {
      const results = circles?.filter(
        (c) =>
          c.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          c.lastName.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchResult(results);
    }
  };

  const deleteCircle = async (id) => {
    onCloseThird();
    await axios.delete(baseUrl + `/circles/${id}`).then((res) => {
      if (res.data.status) {
        toast({
          title: "Circle Deleted",
          description: res.data.message,
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
        setRefetch(refetch + 1);
      } else {
        toast({
          title: "Failed to delete Circle",
          description: res.data.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
  };

  const handleMembers = async (Id) => {
    await axios
      .get(baseUrl + `/circlesUser/getAllUserByCircle/${Id}`)
      .then((res) => {
        if (res.data.status) {
          setMembers(res.data.result);
          onOpen();
        } else {
          toast({
            title: "Failed to load",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
      });
  };

  useEffect(() => {
    if (downloading && !loading && !circlePostLoading) {
      toPDF();
      setDownloading(false);
      setDownloadId(false);
      setLoading(false);
      setCirclePostLoading(false);
    }
  }, [circlePostLoading, downloading, loading, toPDF]);

  const handleEdit = async (Id) => {
    await axios
      .get(baseUrl + `/circlesUser/getAllUserByCircle/${Id}`)
      .then((res) => {
        if (res.data.status) {
          const selectedArray = [];
          for (const i of res.data.result) {
            selectedArray.push(i?.userId?._id);
          }
          setCurrentAdmin(res.data.result[0].circleId.circleAdmin);
          setSelected(selectedArray);
          console.log(selected);
        } else {
          toast({
            title: "Failed to load",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
      });
  };

  const handleAdd = (id) => {
    setSelected([...selected, id]);
  };
  const handleRemove = (id) => {
    // console.log(id);
    const filter = selected.filter((s) => s !== id);
    setSelected(filter);
  };
  const handleSearchUsers = (event) => {
    event.preventDefault();
    const search = event.target.value;
    // console.log(searchText);
    setUserSearchText(search);
    if (userSearchText !== "") {
      const results = allUsers?.filter((user) =>
        user.username.toLowerCase().includes(userSearchText.toLowerCase())
      );
      setUserSearchResult(results);
    }
  };

  async function onSubmit(values) {
    let data;
    if (imageUpload) {
      data = {
        circleAdmin: currentAdmin,
        circleImg: imageUpload,
      };
    } else {
      data = {
        circleAdmin: currentAdmin,
      };
    }

    await axios
      .patch(baseUrl + `/circles/${updateCircle}`, {
        ...data,
        firstName: circleFirstName,
        lastName: circleSecondName,
      })
      .then(async (res) => {
        if (res.data.status) {
          await axios
            .post(baseUrl + "/circles/circleUserUpdate", {
              circleId: updateCircle,
              userIds: selected,
            })
            .then((res) => {
              if (res.data.status) {
                toast({
                  title: "Circle User Updated",
                  description: res.data.message,
                  status: "success",
                  isClosable: true,
                  position: "top",
                  duration: 5000,
                });
                reset();
                setImageUpload("");
                setRefetch(refetch + 1);
                onCloseSecond();
              }
            });
        } else {
          toast({
            title: "Failed to update circles",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
      });
  }

  const handleArchive = async (circle) => {
    await axios
      .patch(baseUrl + `/circles/${circle?._id}`, {
        archive: true,
      })
      .then((res) => {
        if (res?.data?.status) {
          setRefetch(refetch + 1);
          setSelectedId("");
          toast({
            title: "Circle was archived successfully",
            description: res?.data?.message,
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
        });
      } else {
          toast({
            title: "Failed to update circles",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
      }
      });
  };

  if (loading || circlePostLoading)
    return (
      <Box p="2" maxW="150px" textAlign="center" mx={"auto"} mt={"12"}>
        <InfinitySpin
          width="200"
          color="#008080"
          // style={{ marginLeft: "47%", display: "block" }}
        />
      </Box>
    );

  return (
    <>
    <Box p="2" maxW="700px" textAlign="left" mx={"auto"} mt={"12"}>
      <Card
        mx="auto"
        p="10px"
        borderWidth="2px"
        borderColor="0,0,0,0.5"
        style={{ position: "relative" }}
        pt={"12"}
      >
        {user?.role?.toLowerCase() === "admin" && (
          <BsPlusCircleFill
            className="add-circle"
            onClick={() => navigate("/protected/addcircle")}
          />
        )}
        <div className="circle-main">
          {circles.length === 0 ? (
            <>
                <Text textAlign={"center"}>
                  You are not joined to any circles
                </Text>
            </>
          ) : (
            <>
              <div className="circle-main">
                <input
                  onChange={handleSearch}
                  className="search-input-circle"
                  placeholder="Search Circles"
                />
              </div>
            </>
          )}
        </div>
        <div className="card-style">
          {searchText ? (
            <>
              {" "}
              {searchResult
                .filter((c) => !c.archive) // Filter out archived circles
                .map((c) => (
                  <div
                    className="circle-card-style"
                    onClick={() => {
                      selectedId !== "" && setSelectedId("");
                    }}
                    key={c._id}
                  >
                    <div
                      className="name-circle"
                      onClick={() => navigate(`/protected/circlePost/${c?._id}`)}
                    >
                      {c?.circleImg ? (
                        <>
                          <img
                            src={c?.circleImg}
                            className="circle-img"
                            alt="profile img"
                          />
                        </>
                      ) : (
                        <>
                          <FaUserCircle className="user-circle" />{" "}
                        </>
                      )}
                      <h4 style={{ marginLeft: "10px" }}>
                        {c?.firstName} <b>{c?.lastName}</b>
                      </h4>
                    </div>
                    {user?.role?.toLowerCase() === "admin" && (
                      <HiOutlineDotsVertical
                        onClick={() => setSelectedId(c?._id)}
                        className="circle-three-dots"
                      />
                    )}
                    {c?._id === selectedId && (
                      <div className="three-dot-menu-style">
                        <p onClick={() => handleMembers(c?._id)}>
                          View Circle Members
                        </p>
                        <p onClick={() => handleArchive(c)}>Archive Circle</p>
                        <p
                          onClick={() => {
                            setUpdateCircle(c?._id);
                            handleEdit(c?._id);
                            onOpenSecond();
                            setCircleFirstName(c?.firstName);
                            setCircleSecondName(c?.lastName);
                          }}
                        >
                          Edit Circle Settings
                        </p>
                        <p
                          onClick={() => {
                            setSelectedCircleId(c?._id);
                            onOpenThird();
                          }}
                        >
                          Delete Circle
                        </p>
                      </div>
                    )}
                  </div>
                ))}

            </>
          ) : (
            <>
             {circles
              .filter((c) => !c.archive) // Filter out archived circles
              .map((c) => (
                <div
                  className="circle-card-style"
                  onClick={() => {
                    selectedId !== "" && setSelectedId("");
                  }}
                  key={c._id}
                >
                  <div
                    className="name-circle"
                    onClick={() => navigate(`/protected/circlePost/${c?._id}`)}
                  >
                    {c?.circleImg ? (
                      <>
                        <img
                          src={c?.circleImg}
                          className="circle-img"
                          alt="profile img"
                        />
                      </>
                    ) : (
                      <>
                        <FaUserCircle className="user-circle" />{" "}
                      </>
                    )}
                    <h4 style={{ marginLeft: "10px" }}>
                      {c?.firstName} <b>{c?.lastName}</b>
                    </h4>
                  </div>
                  {user?.role?.toLowerCase() === "admin" && (
                    <HiOutlineDotsVertical
                      onClick={() => setSelectedId(c?._id)}
                      className="circle-three-dots"
                    />
                  )}
                  {c?._id === selectedId && (
                    <div className="three-dot-menu-style">
                      <p onClick={() => handleMembers(c?._id)}>
                        View Circle Members
                      </p>
                      <p onClick={() => handleArchive(c)}>Archive Circle</p>
                      <p
                        onClick={() => {
                          setUpdateCircle(c?._id);
                          handleEdit(c?._id);
                          onOpenSecond();
                          setCircleFirstName(c?.firstName);
                          setCircleSecondName(c?.lastName);
                        }}
                      >
                        Edit Circle Settings
                      </p>
                      <p
                        onClick={() => {
                          setSelectedCircleId(c?._id);
                          onOpenThird();
                        }}
                      >
                        Delete Circle
                      </p>
                    </div>
                  )}
                </div>
              ))}

            </>
          )}
        </div>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Circle Members</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              {members?.map((member, i) => (
                <div className="add-circle-card-style" key={i}>
                <div className="name-circle">
                  {member?.userId?.profileImg ? (
                    <img
                      src={fakeImg}
                      height={50}
                      width={50}
                      alt="prfile imgh"
                    />
                  ) : (
                    <FaUserCircle className="user-circle" />
                  )}
                  <h4 style={{ marginLeft: "10px" }}>
                    <b>{member?.userId?.username}</b>
                  </h4>
                </div>
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenSecond} onClose={onCloseSecond}>
        <ModalOverlay />
        <ModalContent>
          <form p="10px" width="100%" onSubmit={handleSubmit(onSubmit)}>
            <Card padding={"3.5"}>
              <ModalCloseButton />
              <p style={{ fontSize: "14px", marginBottom: "20px" }}>
                Manage Access
              </p>
                <FormControl mb={4} isInvalid={errors.dateOfBirth}>
                  <FormLabel htmlFor="circle-name">Circle Name</FormLabel>
                  <Input
                    type="text"
                    id="circle-name"
                    placeholder="Circle Name"
                    onChange={(e) => setCircleFirstName(e.target.value)}
                    value={circleFirstName}
                  />
                </FormControl>
                <FormControl mb={4} isInvalid={errors.dateOfBirth}>
                  <FormLabel htmlFor="circle-second-name">
                    Circle Name
                  </FormLabel>
                  <Input
                    type="text"
                    id="circle-second-name"
                    placeholder="Circle Name"
                    onChange={(e) => setCircleSecondName(e.target.value)}
                    value={circleSecondName}
                  />
                </FormControl>
              <FormControl mb={4}>
                <FormLabel>Admin</FormLabel>
                <Select
                  value={currentAdmin}
                  onChange={(e) => setCurrentAdmin(e.target.value)}
                  placeholder="Select Admin"
                >
                    {admins.map((a, i) => (
                      <option value={a._id} key={i}>
                        {a.username}
                      </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mb={"4"}>
                <FormLabel htmlFor="picture">Update Image</FormLabel>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </FormControl>
              <FormControl mb={4} isInvalid={errors.dateOfBirth}>
                <FormLabel htmlFor="phone">Add Users</FormLabel>
                <Input
                  type="text"
                  id="phone"
                  placeholder="Search Users"
                  onChange={handleSearchUsers}
                />
              </FormControl>
              <div mb={4} className="users-container">
                {userSearchText ? (
                  <>
                      {userSearchResult?.map((user, i) => (
                        <div className="add-circle-card-style" key={i}>
                        <div className="name-circle">
                          {/* <img src={fakeImg} height={50} width={50} alt="prfile imgh"/>  */}
                          <FaUserCircle className="user-circle" />{" "}
                          <h4 style={{ marginLeft: "10px" }}>
                            <b>{user?.username}</b>
                          </h4>
                        </div>
                        {selected.includes(user?._id) ? (
                          <IoIosRemoveCircle
                            onClick={() => handleRemove(user?._id)}
                            className="remove-circle-icon"
                          />
                        ) : (
                          <AiFillPlusCircle
                            onClick={() => handleAdd(user?._id)}
                            className="add-circle-icon"
                          />
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                      {allUsers.map((user, i) => (
                        <div className="add-circle-card-style" key={i}>
                        <div className="name-circle">
                          {/* <img src={fakeImg} height={50} width={50} alt="prfile imgh"/>  */}
                          <FaUserCircle className="user-circle" />{" "}
                          <h4 style={{ marginLeft: "10px" }}>
                            <b>{user?.username}</b>
                          </h4>
                        </div>
                        {selected.includes(user?._id) ? (
                          <IoIosRemoveCircle
                            onClick={() => handleRemove(user?._id)}
                            className="remove-circle-icon"
                          />
                        ) : (
                          <AiFillPlusCircle
                            onClick={() => handleAdd(user?._id)}
                            className="add-circle-icon"
                          />
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
              <Button
                mt={4}
                width={"100%"}
                mx={"auto"}
                colorScheme="blue"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
              <Button
                width={"100%"}
                mt={4}
                mx={"auto"}
                colorScheme="blue"
                onClick={onCloseSecond}
              >
                Close
              </Button>
            </Card>
          </form>
        </ModalContent>
      </Modal>
    </Box>

      <Modal isOpen={isOpenThird} onClose={onCloseThird}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to delete ?</ModalHeader>
          <ModalCloseButton />

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onCloseThird}
            >
              Cancel
            </Button>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="red"
              onClick={() => deleteCircle(selectedCircleId)}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Circles;
