import {
  Box,
  Button,
  Card,
  Text,
  useDisclosure,
  useToast,
  Switch,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import fakeImg from "../../images/fakeImg";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { BsPlusCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "services/api";
import { useAuth } from "hooks/auth";

import { InfinitySpin } from "react-loader-spinner";

const ManageNotification = () => {
  const [loading, setLoading] = useState(true);
  const [postNotifications, setPostNotifications] = useState(null);
  const [commentNotifications, setCommentNotifications] = useState(null);
  const [messageNotifications, setMessageNotifications] = useState(null);

  const { user, isLoading: userLoading } = useAuth();
  const toast = useToast();

  useEffect(() => {
    if (!userLoading) {
      setPostNotifications(user.postNotifications);
      setCommentNotifications(user.commentNotifications);
      setMessageNotifications(user.messageNotifications);
      setLoading(false);
    }
  }, [userLoading]);
  console.log({
    postNotifications,
    messageNotifications,
    commentNotifications,
    user,
  });

  const onChangeHandler = async (e) => {
    try {
      if (e.target.id === "post-email-alerts") {
        setPostNotifications((prevState) => e.target.checked);
      }
      if (e.target.id === "comment-email-alerts") {
        setCommentNotifications((prevState) => e.target.checked);
      }
      if (e.target.id === "message-email-alerts") {
        setMessageNotifications((prevState) => e.target.checked);
      }
      const data = {
        postNotifications,
        commentNotifications,
        messageNotifications,
      };
      const response = await axios.patch(
        `${baseUrl}/user/manageNotification/${user._id}`,
        data
      );
      toast({
        title: "Manage Notification",
        description: response.data.message,
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } catch (error) {
      toast({
        title: "Failed to change notification settings",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
  };

  if (loading)
    return (
      <Box p="2" maxW="150px" textAlign="center" mx={"auto"} mt={"12"}>
        <InfinitySpin
          width="200"
          color="#008080"
          // style={{ marginLeft: "47%", display: "block" }}
        />
      </Box>
    );

  return (
    <Box p="2" maxW="700px" textAlign="left" mx={"auto"} mt={"12"} h={8}>
      <Card
        mx="auto"
        p="10px"
        borderWidth="2px"
        borderColor="0,0,0,0.5"
        style={{ position: "relative" }}
        pt={"12"}
      >
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="post-email-alerts" mb="0">
            Post email alerts
          </FormLabel>
          <Switch
            id="post-email-alerts"
            isChecked={postNotifications}
            onChange={async (e) => {
              setPostNotifications((prevState) => e.target.checked);
              try {
                const data = {
                  postNotifications: e.target.checked,
                  commentNotifications,
                  messageNotifications,
                };
                const response = await axios.patch(
                  `${baseUrl}/user/manageNotification/${user._id}`,
                  data
                );
                toast({
                  title: "Manage Notification",
                  description: response.data.message,
                  status: "success",
                  isClosable: true,
                  position: "top",
                  duration: 5000,
                });
              } catch (error) {
                toast({
                  title: "Failed to change notification settings",
                  description: error.response.data.message,
                  status: "error",
                  isClosable: true,
                  position: "top",
                  duration: 5000,
                });
              }
            }}
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="comment-email-alerts" mb="0">
            Comment email alerts
          </FormLabel>
          <Switch
            id="comment-email-alerts"
            isChecked={commentNotifications}
            onChange={async (e) => {
              setCommentNotifications((prevState) => e.target.checked);
              try {
                const data = {
                  postNotifications,
                  commentNotifications: e.target.checked,
                  messageNotifications,
                };
                const response = await axios.patch(
                  `${baseUrl}/user/manageNotification/${user._id}`,
                  data
                );
                toast({
                  title: "Manage Notification",
                  description: response.data.message,
                  status: "success",
                  isClosable: true,
                  position: "top",
                  duration: 5000,
                });
              } catch (error) {
                toast({
                  title: "Failed to change notification settings",
                  description: error.response.data.message,
                  status: "error",
                  isClosable: true,
                  position: "top",
                  duration: 5000,
                });
              }
            }}
          />
        </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="message-email-alerts" mb="0">
              Message email alerts
            </FormLabel>
            <Switch
              id="message-email-alerts"
              isChecked={messageNotifications}
              onChange={async (e) => {
                setMessageNotifications((prevState) => e.target.checked);
                try {
                  const data = {
                    postNotifications,
                    commentNotifications,
                    messageNotifications: e.target.checked,
                  };
                  const response = await axios.patch(
                    `${baseUrl}/user/manageNotification/${user._id}`,
                    data
                  );
                  toast({
                    title: "Manage Notification",
                    description: response.data.message,
                    status: "success",
                    isClosable: true,
                    position: "top",
                    duration: 5000,
                  });
                } catch (error) {
                  toast({
                    title: "Failed to change notification settings",
                    description: error.response.data.message,
                    status: "error",
                    isClosable: true,
                    position: "top",
                    duration: 5000,
                  });
                }
              }}
            />
          </FormControl>
      </Card>
    </Box>
  );
};

export default ManageNotification;
