import {
  Box,
  Flex,
  IconButton,
  Text,
  Card,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import Avatar from "components/profile/Avatar";
import UsernameButton from "components/profile/UsernameButton";
import { format, formatDistanceToNow } from "date-fns";
import { useAuth } from "hooks/auth";
import { useDeleteComment } from "hooks/comments";
import { useUser } from "hooks/users";
import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { baseUrl } from "services/api";

export default function Comment({ comment, refetch, setRefetch, from }) {
  const { commentBody, createdAt, _id, commentedBy } = comment;
  const { user, isLoading: userLoading } = useAuth();
  const toast = useToast();
  const [desc, setDesc] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenThird,
    onOpen: onOpenThird,
    onClose: onCloseThird,
  } = useDisclosure();
  if (userLoading) return "Loading...";
  const deleteComment = async (id) => {
    onCloseThird();
    await axios.delete(baseUrl + `/comments/${id}`).then((res) => {
      if (res.data.status) {
        toast({
          title: "Comment Deleted",
          description: res.data.message,
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
        setRefetch(refetch + 1);
      } else {
        toast({
          title: "Failed to delete Comment",
          description: res.data.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
  };

  const handleEdit = async () => {
    await axios
      .patch(baseUrl + `/comments/edit/${_id}`, { commentBody: desc })
      .then((res) => {
      if (res.data.status) {
          setDesc("");
          onClose();
        toast({
          title: "Comment Edited",
          description: res.data.message,
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
        setRefetch(refetch + 1);
      } else {
        toast({
          title: "Failed to edit Comment",
          description: res.data.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
  };
  return (
    <Card
      maxW="600px"
      mx="auto"
      p="10px"
      borderWidth="2px"
      borderColor="0,0,0,0.5"
      textAlign="left"
      mb={4}
    >
      <Flex pb="2">
        <Avatar user={commentedBy} size="sm" />
        <Box flex="1" ml="4">
          <Flex
            borderBottom="1px solid"
            borderColor="blue.100"
            pb="2"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
              <UsernameButton user={commentedBy} />
              <Text fontSize="xs" color="gray.500">
                {createdAt && (
                  <>   {from === "exportPdf" ? (
                    <>
                      {format(
                        new Date(createdAt),
                        "yyyy-MM-dd' at 'HH:mm:ss"
                      )}
                    </>
                  ) : (
                    <>{formatDistanceToNow(new Date(createdAt))} ago</>
                  )}</>
                )}
              </Text>
            </Box>
            {
              from !== "exportPdf" &&         <Box>
            {user?.role === "admin" && (
              <IconButton
                size="sm"
                ml="auto"
                icon={<FaTrash />}
                colorScheme="red"
                variant="ghost"
                isRound
                  onClick={() => {
                    onOpenThird();
                  }}
              />
            )}
              {user?.role?.toLowerCase() === "admin" && (
                <IconButton
                  ml="auto"
                  onClick={() => {
                    onOpen();
                  }}
                  size="md"
                  colorScheme="red"
                  variant="ghost"
                  icon={<FaEdit />}
                  isRound
                />
              )}
            </Box>
            }
    
          </Flex>
          <Box pt="2" fontSize="sm">
            <Text>{commentBody}</Text>
          </Box>
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Post</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Input
                size="sm"
                variant="flushed"
                placeholder="Update comment..."
                autoComplete="off"
                type="text"
                onChange={(e) => setDesc(e.target.value)}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={handleEdit}
            >
              Update
            </Button>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenThird} onClose={onCloseThird}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to delete ?</ModalHeader>
          <ModalCloseButton />

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onCloseThird}
            >
              Cancel
            </Button>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="red"
              onClick={() => deleteComment(_id)}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
