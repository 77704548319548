import { Box } from "@chakra-ui/react";
import { useComments } from "hooks/comments";
import Comment from "./Comment";

export default function CommentList({ comments, refetch, setRefetch, from }) {
  // const { id } = post;
  // const { comments, isLoading } = useComments(id);

  // if (isLoading) return "Loading...";

  return (
    <Box>
      {comments?.map((comment) => (
        <Comment
          refetch={refetch}
          setRefetch={setRefetch}
          key={comment?._id}
          comment={comment}
          from={from}
        />
      ))}
    </Box>
  );
}
