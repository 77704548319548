import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useAuth } from "hooks/auth";
import {
  FaRegHeart,
  FaHeart,
  FaComment,
  FaRegComment,
  FaTrash,
  FaUserAlt,
  FaEdit,
  FaUserCircle,
} from "react-icons/fa";
import { useToggleLike, useDeletePost } from "hooks/posts";
import { Link, useNavigate } from "react-router-dom";
import { PROTECTED } from "lib/routes";
import { useComments } from "hooks/comments";
import axios from "axios";
import { baseUrl } from "services/api";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import fakeImg from "../../images/fakeImg";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import "./post.css";
export default function Actions({ post, refetch, setRefetch, from }) {
  const { _id, likes } = post;
  const navigate = useNavigate();
  // console.log("action", _id)
  const toast = useToast();
  const { user, getUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenMember,
    onOpen: onOpenMember,
    onClose: onCloseMember,
  } = useDisclosure();
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [likedUserData, setLikedUserData] = useState(null);
  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      post?.isText
        ? ContentState.createFromText(post?.postBody)
        : convertFromRaw(JSON.parse(post?.postBody))
    )
  );
  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const deletePost = async (id) => {
    // console.log(post?._id);
    await axios.delete(baseUrl + `/posts/${post?._id}`).then((res) => {
      if (res.data.status) {
        toast({
          title: "Post Deleted",
          description: res.data.message,
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
        setRefetch(refetch + 1);
        from && navigate(-1);
      } else {
        toast({
          title: "Failed to delete Post",
          description: res.data.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
  };

  const handleUpdate = async (task) => {
    await axios
      .patch(baseUrl + `/posts/${_id}`, { task: task, userId: user?._id })
      .then((res) => {
      if (res.data.status) {
        toast({
          title: "Post Liked",
          description: res.data.message,
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
        setRefetch(refetch + 1);
        getUser();
      } else {
        toast({
          title: "Failed to like Post",
          description: res.data.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
  };

  const handleEdit = async (_id) => {
    let desc = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    await axios
      .patch(baseUrl + `/posts/edit/${_id}`, { postBody: desc })
      .then((res) => {
        if (res.data.status) {
          toast({
            title: "Post Edited",
            description: res.data.message,
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
          setRefetch(refetch + 1);
          getUser();
          // setEditorState("");
          onClose();
        } else {
          toast({
            title: "Failed to edit Post",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
      });
  };

  const getLikedUserByPostId = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/posts/getLikedUserByPostId/${_id}`
      );

      // console.log("response: " + JSON.stringify(response));

      setLikedUserData(response.data?.data);
    } catch (error) {
      toast({
        title: "Failed to load Post like",
        description: error.response.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
  };

  useEffect(() => {
    if (user?.role?.toLowerCase() === "admin") {
      getLikedUserByPostId();
    }
  }, [user]);

  // console.log(
  //   "liked user",
  //   `${window.location.protocol}//${window.location.host}`
  // );
  return (
    <>
      <Flex p="2" justifyContent={"space-between"} alignItems={"center"}>
      {/* like button */}
      <Flex alignItems="center">
          <Box marginRight="4">
            {user?.role?.toLowerCase() === "admin" && likedUserData && (
              <Button
                sx={{ padding: " 0 10px", bgColor: "#ffff" }}
                className="liked-user-btn"
                onClick={() => onOpenMember()}
              >
                Likes
              </Button>
            )}
        <IconButton
          size="md"
          colorScheme="red"
          variant="ghost"
          icon={
            user?.likes?.includes(_id) ? (
              <FaHeart onClick={() => handleUpdate("removeLike")} />
            ) : (
              <FaRegHeart onClick={() => handleUpdate("addLike")} />
            )
          }
          isRound
        />
        {post?.likes}
          </Box>

          <Box>
        <IconButton
          as={Link}
          to={`${PROTECTED}/comments/${_id}`}
          // isLoading={commentsLoading}
          size="md"
          colorScheme="blue"
          variant="ghost"
          icon={
                user?.comments?.includes(_id) ? <FaComment /> : <FaRegComment />
          }
          isRound
        />
            {from === "exportPdf" ? (
              <>
                {post?.comments[0]?.commentBody ? (
                  <>{post?.comments?.length}</>
                ) : (
                  <>0</>
                )}
              </>
            ) : (
              <>{post?.comments}</>
            )}
          </Box>
      </Flex>

        {/* protected comment route button */}

      {/* delete button if user is the one that posted*/}
        {from !== "exportPdf" && (
          <>
        <Flex justifyContent={"flex-end"} alignItems={"center"}>
          {user?.role?.toLowerCase() === "admin" && (
        <IconButton
          ml="auto"
              onClick={() => {
                onOpen();
                setSelectedPostId(post?._id);
                setShowEdit(false);
                setShowDelete(true);
              }}
          size="md"
          colorScheme="red"
          variant="ghost"
          icon={<FaTrash />}
          isRound
        />
      )}
          {user?.role?.toLowerCase() === "admin" && (
            <IconButton
              ml="auto"
              onClick={() => {
                onOpen();
                setShowDelete(false);
                setShowEdit(true);
                // handleEdit(post?._id)
              }}
              size="md"
              colorScheme="red"
              variant="ghost"
              icon={<FaEdit />}
              isRound
            />
          )}
    </Flex>
          </>
        )}
      </Flex>
      {showEdit && (
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(post?.postBody))
            )
          );
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box border="2px solid teal" marginTop={"5px"}>
              <Editor
                editorState={editorState}
                onEditorStateChange={(state) => {
                  // console.log("=========", state);
                  handleEditorChange(state);
                }}
                toolbar={{
                  options: ["inline", "list"],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: [
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "monospace",
                    ],
                  },
                  list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["unordered", "ordered"],
                  },
                }}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={() => handleEdit(_id)}
            >
              Update
            </Button>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={() => {
                setEditorState(
                  EditorState.createWithContent(
                    convertFromRaw(JSON.parse(post?.postBody))
                  )
                );
                onClose();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      )}
      {showDelete && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Are you sure you want to delete ?</ModalHeader>
            <ModalCloseButton />

            <ModalFooter>
              <Button
                display={"block"}
                mx={"auto"}
                colorScheme="blue"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                display={"block"}
                mx={"auto"}
                colorScheme="red"
                onClick={() => deletePost(selectedPostId)}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Modal isOpen={isOpenMember} onClose={onCloseMember}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Circle Members</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {likedUserData?.map((member, i) => (
              <div className="add-circle-card-style" key={i}>
                <div className="name-circle">
                  {member?.profileImg ? (
                    <img
                      src={member?.profileImg}
                      height={50}
                      width={50}
                      alt="prfile imgh"
                    />
                  ) : (
                    <FaUserCircle className="user-circle" />
                  )}
                  <h4 style={{ marginLeft: "10px" }}>
                    <b>{member?.username}</b>
                  </h4>
                </div>
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onCloseMember}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
