import { useState } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import Post from "./index";
import { usePDF } from "react-to-pdf";

export default function PostsList({
  posts,
  refetch,
  setRefetch,
  page,
  setPage,
  total,
}) {
  const [visiblePosts, setVisiblePosts] = useState(5);

  const loadMorePosts = () => {
    // setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 5);
    setPage((prevVisiblePosts) => prevVisiblePosts + 1);
  };

  return (
    <Box px="4" align="center">
      {posts?.length === 0 ? (
        <Text textAlign="center" fontSize="xl">
          Loading Posts...
        </Text>
      ) : (
        <>
          {posts
            ?.filter((post) => !post.archive) // Filter out archived posts
            .map((post) => (
              <Post
                refetch={refetch}
                setRefetch={setRefetch}
                key={post._id}
                post={post}
              />
            ))}
          {total > posts?.length && (
            <Button mt="4" colorScheme="blue" onClick={loadMorePosts}>
              Load More
            </Button>
          )}
        </>
      )}
    </Box>
  );
}
