import { Avatar as ChakraAvatar } from "@chakra-ui/react";
import { useAuth } from "hooks/auth";
import { PROTECTED } from "lib/routes";
import { Link } from "react-router-dom";

export default function Avatar({ user, size = "xl", overrideAvatar = null }) {
  const { user: UserMain } = useAuth();
  return (
   <>
      {UserMain?.role === "admin" ? (
        <ChakraAvatar
      as={Link}
      to={`${PROTECTED}/profile/${user?._id}`}
      name={user?.username}
      size={size}
      src={overrideAvatar || user?.profileImg}
      _hover={{ cursor: "pointer", opacity: "0.8" }}
        />
      ) : (
        <ChakraAvatar
      name={user?.username}
      size={size}
      src={overrideAvatar || user?.profileImg}
      _hover={{ cursor: "pointer", opacity: "0.8" }}
    />
      )}
   </>
  );
}
