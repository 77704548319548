import { createBrowserRouter } from "react-router-dom";

import Archives from "components/Archives/Archives";
import AddCareManuals from "components/CareManuals/AddCareManuals";
import CareManuals from "components/CareManuals/CareManuals";
import CareManualsPost from "components/CareManuals/CareManualsPost";
import CirclePost from "components/CirclePost/CirclePost";
import AddCircle from "components/Circles/AddCircle";
import Circles from "components/Circles/Circles";
import ManageNotification from "components/Notifications";
import ResetPassword from "components/ResetPassword/ResetPassword";
import Forgot from "components/auth/Forgot";
import Invite from "components/auth/Invite";
import Login from "components/auth/Login";
import Register from "components/auth/Register";
import Reset from "components/auth/Reset";
import AddChat from "components/chat/AddChat";
import Chat from "components/chat/Chat";
import ChatLog from "components/chat/ChatLog";
import Chats from "components/chat/Chats";
import Comments from "components/comments";
import Homepage from "components/dashboard";
import Layout from "components/layout";
import Profile from "components/profile";
import Users from "components/users";
import AllUsers from "components/users/users";
// import

export const ROOT = "/";
export const LOGIN = "/login";
export const FORGOT = "/forgot-password";
export const RESET = "/reset-password";
export const INVITE = "/invite/:token";

export const PROTECTED = "/protected";
export const HOMEPAGE = "/protected/dashboard";
export const USERS = "/protected/users";
export const ALLUSERS = "/protected/allusers";
export const PROFILE = "/protected/profile/:id";
export const COMMENTS = "/protected/comments/:id";
export const REGISTER = "/protected/register";
export const CIRCLE = "/protected/circle";
export const ADDCIRCLE = "/protected/addcircle";
export const CIRCLEPOST = "/protected/circlePost/:id";
export const CAREMANUALS = "/protected/caremanuals";
export const ADDCAREMANUALS = "/protected/add-care-manuals";
export const CAREMANUALSPOST = "/protected/caremanuals-post/:id";
export const RESETPASSWORD = "/protected/reset-password";
export const ADDCHAT = "/protected/addchat";
export const CHATS = "/protected/chat";
export const CHAT = "/protected/chat/:id";
export const CHATLOG = "/protected/chat-log/:id";

export const MANAGENOTIFICATION = "/protected/notification";
export const ARCHIVES = "/protected/archives";

export const router = createBrowserRouter([
  { path: ROOT, element: <Login /> },
  { path: LOGIN, element: <Login /> },
  { path: FORGOT, element: <Forgot /> },
  { path: RESET, element: <Reset /> },
  { path: INVITE, element: <Invite /> },

  {
    path: PROTECTED,
    element: <Layout />,
    children: [
      {
        path: HOMEPAGE,
        element: <Homepage />,
      },
      {
        path: ADDCHAT,
        element: <AddChat />,
      },
      {
        path: CHATS,
        element: <Chats />,
      },
      {
        path: CHAT,
        element: <Chat />,
      },
      {
        path: CHATLOG,
        element: <ChatLog />,
      },
      {
        path: USERS,
        element: <Users />,
      },
      {
        path: PROFILE,
        element: <Profile />,
      },
      {
        path: COMMENTS,
        element: <Comments />,
      },
      {
        path: REGISTER,
        element: <Register />,
      },
      {
        path: CIRCLE,
        element: <Circles />,
      },
      {
        path: ADDCIRCLE,
        element: <AddCircle />,
      },
      {
        path: CIRCLEPOST,
        element: <CirclePost />,
      },
      {
        path: CAREMANUALS,
        element: <CareManuals />,
      },
      {
        path: ADDCAREMANUALS,
        element: <AddCareManuals />,
      },
      {
        path: CAREMANUALSPOST,
        element: <CareManualsPost />,
      },
      {
        path: RESETPASSWORD,
        element: <ResetPassword />,
      },
      {
        path: ALLUSERS,
        element: <AllUsers />,
      },
      {
        path: MANAGENOTIFICATION,
        element: <ManageNotification />,
      },
      {
        path: ARCHIVES,
        element: <Archives />,
      },
    ],
  },
]);
