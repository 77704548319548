import {
  Box,
  Button,
  Code,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import Avatar from "components/profile/Avatar";
import React, { useEffect, useState } from "react";
import { InfinitySpin } from "react-loader-spinner";
import { baseUrl } from "services/api";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [selectedId, setSelectedId] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();
  const [userSearchResult, setUserSearchResult] = useState([]);
  const [userSearchText, setUserSearchText] = useState("");
  useEffect(() => {
    axios.get(`${baseUrl}/user/getAllUserInfo`).then((res) => {
      if (res.data.status) {
        setAllUsers(res.data.result);
        setLoading(false);
      }
    });
  }, []);

  const handleUpdate = async (Id) => {
    await axios
      .patch(baseUrl + `/user/updatePassword/${selectedId}`, {
        password: password,
      })
      .then((res) => {
        if (res.data.status) {
          toast({
            title: "Password Updated",
            description: res.data.message,
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
          onClose();
        } else {
          toast({
            title: "Failed to Update",
            description: res.data.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        }
      });
  };

  const handleSearchUsers = (event) => {
    event.preventDefault();
    const search = event.target.value;
    console.log(search);
    setUserSearchText(search);
    if (userSearchText !== "") {
      const results = allUsers?.filter((user) =>
        user.username.toLowerCase().includes(userSearchText.toLowerCase())
      );
      setUserSearchResult(results);
    }
  };

  if (loading)
    return (
      <Box p="2" maxW="150px" textAlign="center" mx={"auto"} mt={"12"}>
        <InfinitySpin
          width="200"
          color="#008080"
          // style={{ marginLeft: "47%", display: "block" }}
        />
      </Box>
    );

  return (
    <>
     <div className="circle-main" >
              <input
                onChange={handleSearchUsers}
                className="search-input-circle"
                placeholder="Search User to Reset Password"
                style={{marginTop: "30px"}}
              />
            </div>
      <SimpleGrid columns={[2,4, 6]} spacing={[2, 3]} px="10px" py="6">
        {
          userSearchText ?
          <>
          {userSearchResult?.map((user) => (
          <VStack
            bg="gray.100"
            shadow="sm"
            rounded="md"
            textAlign="center"
            p="4"
            spacing="3"
          >
            <Avatar user={user} />
            <Code>{user?.username}</Code>
            <Button onClick={()=> {
                setSelectedId(user?._id)
                onOpen()
            }} size="sm" variant="link" colorScheme="blue">
              Reset Password
            </Button>
          </VStack>
        ))}
          </>
          :
          <>
          {allUsers?.map((user) => (
          <VStack
            bg="gray.100"
            shadow="sm"
            rounded="md"
            textAlign="center"
            p="4"
            spacing="3"
          >
            <Avatar user={user} />
            <Code>@{user?.username}</Code>
            <Button onClick={()=> {
                setSelectedId(user?._id)
                onOpen()
            }} size="sm" variant="link" colorScheme="blue">
              Reset Password
            </Button>
          </VStack>
        ))}
          </>
        }
      </SimpleGrid>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </ModalBody>

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResetPassword;
