import { Box } from "@chakra-ui/react";
import Post from "components/post";
import { usePost } from "hooks/posts";
import { useParams } from "react-router-dom";
import NewComment from "./NewComment";
import CommentList from "./CommentList";
import { useEffect, useState } from "react";
import { baseUrl } from "services/api";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import { InfinitySpin } from "react-loader-spinner";
import { PostServices } from "services/postServices";

export default function Comments() {
  const { id } = useParams();
  const [comments, setComments] = useState([]);
  const [post, setPost] = useState([]);
  const [refetch, setRefetch] = useState(0);
  const [desc, setDesc] = useState(null);
  const [loading, setLoading] = useState(true);

  async function fetchPosts() {
    let newPosts = await PostServices.fetchPostById(id);
    setPost(newPosts);
    setLoading(false);
  }
  useEffect(() => {
    // axios.get(baseUrl + "/posts/" + id).then((res) => {
    //   if (res.data.status) {
    //     const content = draftToHtml(JSON.parse(res?.data?.result?.postBody));
    //     setPost({ ...res.data.result, content });
    //     setLoading(false);
    //   }
    // });
    fetchPosts();
  }, [id, refetch]);
  useEffect(() => {
    axios.get(baseUrl + "/comments/getCommentByPostId/" + id).then((res) => {
      if (res.data.status) {
        setComments(res.data.result);
      }
    });
  }, [id, refetch]);
  // console.log("here", comments, id);
  // if (isLoading) return "Loading...";
  if (loading)
    return (
      <Box p="2" maxW="150px" textAlign="center" mx={"auto"} mt={"12"}>
        <InfinitySpin
          width="200"
          color="#008080"
          // style={{ marginLeft: "47%", display: "block" }}
        />
      </Box>
    );
  return (
    <Box align="center" pt="50">
      <Post refetch={refetch} setRefetch={setRefetch} post={post} from={true} />
      <NewComment refetch={refetch} setRefetch={setRefetch} post={post} />
      {comments.length !== 0 && (
        <CommentList
          refetch={refetch}
          setRefetch={setRefetch}
          comments={comments}
        />
      )}
    </Box>
  );
}
