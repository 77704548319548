import { Button, Flex, Link , Image } from "@chakra-ui/react";
import { HOMEPAGE } from "lib/routes";
import { Link as RouterLink } from "react-router-dom";
import { useLogout } from "hooks/auth";
import excellconnectlogo from '../../images/excellconnectlogo.jpg';

export default function Navbar() {
  const { logout, isLoading } = useLogout();

  return (
    <Flex
      shadow="sm"
      pos="fixed"
      width="full"
      borderTop="10px solid"
      borderTopColor="blue.300"
      zIndex="3"
      justify="center"
      bg="white"
      height="80px"
    >

      <Flex px="4" w="full" align="center" maxW="1200px">
        <Flex>
          <Button
          ml="auto"
          colorScheme="blue"
          size="sm"
          as={RouterLink} 
          to={HOMEPAGE}
        >
          Home
        </Button></Flex>
        <Flex px="4" align="center" maxW="200px">
          <Link as={RouterLink} to={HOMEPAGE}><Image pb='10px' src={excellconnectlogo} /></Link>
        </Flex>
        <Button
          ml="auto"
          colorScheme="blue"
          size="sm"
          onClick={logout}
          isLoading={isLoading}
        >
          Logout
        </Button>
      </Flex>
    </Flex>
  );
}
