import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useToast,
} from "@chakra-ui/react";
import { LOGIN } from "lib/routes";
import { useForm } from "react-hook-form";
import { otpValidate, passwordValidate } from "utils/form-validate";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "services/api";
const StyledInput = styled(Input)`
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const Reset = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [userData, setUserData] = useState({});
  useEffect(() => {
    const forgotPasswordData = localStorage.getItem("forgotPasswordData");
    if (forgotPasswordData) {
      setUserData(JSON.parse(forgotPasswordData));
    } else {
      navigate("/login");
    }
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  async function handleResetPassword(data) {
    try {
      const { otp, password } = data;
      const body = {
        otp,
        password,
        email: userData.email,
        userId: userData.userId,
      };
      const response = await axios.post(`${baseUrl}/user/resetPassword`, body);
      toast({
        title: "Reset password",
        description: response.data.message,
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      navigate(LOGIN);
    } catch (error) {
      toast({
        title: "Failed to reset password",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
  }

  return (
    <>
      <Center w="100%" h="100vh">
        <Box mx="1" maxW="xl" p="9" borderWidth="1px" borderRadius="lg">
          <Heading mb="4" size="lg" textAlign="center">
            Reset Password
          </Heading>

          <form onSubmit={handleSubmit(handleResetPassword)}>
            <FormControl isInvalid={errors.otp} py="2">
              <FormLabel>OTP</FormLabel>
              <StyledInput
                type="number"
                placeholder="123456"
                {...register("otp", otpValidate)}
              />
              <FormErrorMessage>
                {errors.otp && errors.otp.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password} py="2">
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                placeholder="password"
                {...register("password", passwordValidate)}
              />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <Link
              onClick={async () => {
                try {
                  // console.log(data);

                  const body = {
                    email: userData.email,
                  };
                  const response = await axios.post(
                    `${baseUrl}/user/forgotPassword`,
                    body
                  );

                  localStorage.setItem(
                    "forgotPasswordData",
                    JSON.stringify({
                      email: response.data.data.email,
                      userId: response.data.data.userId,
                    })
                  );
                  toast({
                    title: "Forgot password",
                    description: response.data.message,
                    status: "success",
                    isClosable: true,
                    position: "top",
                    duration: 5000,
                  });
                } catch (error) {
                  toast({
                    title: "Failed to forgot password",
                    description: error.response.data.message,
                    status: "error",
                    isClosable: true,
                    position: "top",
                    duration: 5000,
                  });
                }
              }}
            >
              Resend OTP
            </Link>
            <Button mt="4" type="submit" colorScheme="blue" size="md" w="full">
              Reset Password
            </Button>
          </form>
        </Box>
      </Center>
    </>
  );
};

export default Reset;
