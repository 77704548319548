import {
    Box,
    Card,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { baseUrl } from "services/api";
import ArchiveCaremanuals from "./ArchiveCaremanuals";
import ArchiveCircles from "./ArchiveCircles";

const Archives = () => {
  const [circles, setCircles] = useState([]);
  const [careManuals, setCareManuals] = useState([]);
  const [refetchCircles, setRefetchCircles] = useState(0);
  const [refetchCareManuals, setRefetchCareManuals] = useState(0);

  useEffect(() => {
    const fetchCircles = async () => {
      const res = await fetch(`${baseUrl}/circles/getArchiveCircles`);
      const data = await res.json();
      setCircles(data?.result);
    };
    fetchCircles();
  }, [refetchCircles]);

  useEffect(() => {
    const fetchCareManuals = async () => {
      const res = await fetch(`${baseUrl}/care-manuals/getArchiveCareManuals`);
      const data = await res.json();
      setCareManuals(data?.result);
    };
    fetchCareManuals();
  }, [refetchCareManuals]);

  return (
    <Box p="2" maxW="700px" mx={"auto"} mt={"12"}>
      <Card
        mx="auto"
        p="10px"
        borderWidth="2px"
        borderColor="0,0,0,0.5"
        style={{ position: "relative" }}
        pt={"12"}
      >
        <Text
          fontSize={"3xl"}
          fontWeight={"bold"}
          textAlign={"center"}
          marginBottom={"20px"}
        >
          Archives
        </Text>
        <Tabs variant="unstyled" p="2" maxW="700px">
          <TabList background={"gray.100"}>
            <Tab
              width={"50%"}
              _selected={{ color: "white", bg: "green.500", width: "50%" }}
            >
              Circles
            </Tab>
            <Tab
              width={"50%"}
              _selected={{ color: "white", bg: "green.400", width: "50%" }}
            >
              Care Manuals
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ArchiveCircles
                circles={circles}
                refetch={refetchCircles}
                setRefetch={setRefetchCircles}
              />
            </TabPanel>
            <TabPanel>
              <ArchiveCaremanuals
                careManuals={careManuals}
                refetch={refetchCareManuals}
                setRefetch={setRefetchCareManuals}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
};

export default Archives;
