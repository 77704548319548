import {
  Box,
  Button,
  Card,
  Flex,
  IconButton,
  Input,
  List,
  ListItem,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./chat.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "hooks/auth";
import Resizer from "react-image-file-resizer";
import { baseUrl, backendUrl } from "services/api";
import { InfinitySpin } from "react-loader-spinner";
import { AiFillLeftCircle, AiOutlineArrowRight } from "react-icons/ai";
import io from "socket.io-client";
const socket = io.connect(backendUrl);

const ChatLog = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { user, isLoading: userLoading } = useAuth();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  //   console.log(id);
  useEffect(() => {
    if (!userLoading) {
      socket.emit("join_room", {
        username: user.username,
        room: id,
        userId: user._id,
        role: user.role,
      });
      socket.on("previous_messages", (msg) => {
        // handle the previous messages
        // console.log(msg);
        setMessages(msg);
        setLoading(false);
      });
    }
  }, [userLoading]);

  useEffect(() => {
    socket.on("receive_message", (data) => {
      // console.log(data);
      setMessages((state) => [...state, data]);
    });

    return () => socket.off("receive_message");
  }, [socket]);
  function formatDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString();
  }
  useEffect(() => {
    const messagesContainer = document.getElementById("messages-container-id");
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]);

  //   console.log(messages);
  if (loading)
    return (
      <Box p="2" maxW="150px" textAlign="center" mx={"auto"} mt={"12"}>
        <InfinitySpin
          width="200"
          color="#008080"
          // style={{ marginLeft: "47%", display: "block" }}
        />
      </Box>
    );

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message !== "") {
      const timestamp = Date.now();

      socket.emit("send_message", {
        username: user.username,
        room: id,
        message,
        timestamp,
        userId: user._id,
        role: user.role,
      });
      setMessage("");
    }
  };
  return (
    <Box p="2" maxW="700px" textAlign="left" mx={"auto"} mt={"12"} height="90%">
      <Card
        mx="auto"
        p="10px"
        borderWidth="2px"
        borderColor="0,0,0,0.5"
        style={{
          position: "relative",
          height: "100%",
        }}
        pt={"12"}
      >
        <Flex mb={4}>
          <AiFillLeftCircle
            className="add-circle-back"
            onClick={() => navigate("/protected/chat")}
          />
          <Text mt={-1} ms={4} fontSize="3xl">
            Chat Group
          </Text>
        </Flex>
        <Flex direction="column" className="chat-container">
          <Flex
            direction="column"
            overflow="auto"
            className="chat-messages-container"
            id="messages-container-id"
          >
            <div>
              {messages.length === 0 ? (
                <Box
                  fontSize="sm"
                  fontWeight="bold"
                  mb="1"
                  textTransform="capitalize"
                >
                  There is no message available yet
                </Box>
              ) : (
                messages.map((msg, i) => {
                  return (
                    <>
                      {user?._id !== msg.userId ? (
                        <Box
                          bg="#fff"
                          borderRadius="md"
                          boxShadow="sm"
                          px="6"
                          py="2"
                          mb="2"
                          key={i}
                        >
                          <Box
                            fontSize="sm"
                            fontWeight="bold"
                            mb="1"
                            textTransform="capitalize"
                          >
                            {msg.username}{" "}
                            {msg.role === "staff" && (
                              <small style={{ textTransform: "lowercase" }}>
                                {" "}
                                (staff)
                              </small>
                            )}
                          </Box>
                          <Box fontSize="md">{msg.message}</Box>
                          <Box fontSize="sm" color="#888">
                            {formatDateFromTimestamp(msg.timestamp)}
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          bg="#f2f2f2"
                          borderRadius="md"
                          boxShadow="sm"
                          px="6"
                          py="2"
                          mb="2"
                          alignSelf="flex-end"
                        >
                          <Box
                            fontSize="sm"
                            fontWeight="bold"
                            mb="1"
                            textAlign="right"
                            textTransform="capitalize"
                          >
                            {msg.username}{" "}
                            {msg.role === "staff" && (
                              <small style={{ textTransform: "lowercase" }}>
                                {" "}
                                (staff)
                              </small>
                            )}
                          </Box>
                          <Box fontSize="md" textAlign="right">
                            {msg.message}
                          </Box>
                          <Box fontSize="sm" color="#888" textAlign="right">
                            {formatDateFromTimestamp(msg.timestamp)}
                          </Box>
                        </Box>
                      )}
                    </>
                  );
                })
              )}
            </div>
          </Flex>
          {user.role !== "admin" && (
            <form onSubmit={handleSendMessage}>
              <Flex alignItems="center" bgColor="white">
                <Input
                  type="text"
                  placeholder="Type a message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="chat-input"
                />
                <IconButton
                  aria-label="Send"
                  icon={<AiOutlineArrowRight />}
                  onClick={handleSendMessage}
                  style={{ margin: "5px" }}
                />
              </Flex>
            </form>
          )}
        </Flex>
      </Card>
    </Box>
  );
};

export default ChatLog;
