import { Box, Button, Flex, Input, useToast } from "@chakra-ui/react";
import axios from "axios";
import Avatar from "components/profile/Avatar";
import { useAuth } from "hooks/auth";
import { useAddComment } from "hooks/comments";
import { useForm } from "react-hook-form";
import { baseUrl } from "services/api";

export default function NewComment({ post, refetch, setRefetch }) {
  const { user, isLoading: authLoading } = useAuth();
  const { register, handleSubmit, reset } = useForm();
  const toast = useToast();

  async function handleAddComment(data) {
    const d = {
      commentBody: data.text,
      commentedBy: user?._id,
      circleId: post?.circleId,
      postId: post?._id,
    };
    await axios.post(baseUrl + "/comments", d).then((res) => {
      if (res.data.status) {
        toast({
          title: "Comment added",
          description: res.data.message,
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
        setRefetch(refetch + 1);
        reset();
      } else {
        toast({
          title: "Failed to add Comment",
          description: res.data.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
  }

  if (authLoading) return "Loading...";

  return (
    <Box maxW="600px" mx="auto" py="6">
      <Flex padding="4">
        <Avatar user={user} size="sm" />
        <Box flex="1" ml="4">
          <form onSubmit={handleSubmit(handleAddComment)}>
            <Box>
              <Input
                size="sm"
                variant="flushed"
                placeholder="Write comment..."
                autoComplete="off"
                {...register("text", { required: true })}
              />
            </Box>
            <Flex pt="2">
              <Button
                // isLoading={commentLoading || authLoading}
                type="submit"
                colorScheme="blue"
                size="xs"
                ml="auto"
              >
                Add Comment
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}
