import { Box, Button, Code, Stack } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";
import { useAuth } from "hooks/auth";
import {
  ALLUSERS,
  ARCHIVES,
  CAREMANUALS,
  CHATS,
  CIRCLE,
  HOMEPAGE,
  PROTECTED,
  REGISTER,
  RESETPASSWORD,
  USERS,
} from "lib/routes";
import { RxGear } from "react-icons/rx";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";

function ActiveUser() {
  const { user, isLoading } = useAuth();

  if (isLoading)
    return (
      <Box p="2" maxW="200%" textAlign="center" mx={"auto"}>
        <Puff
          height="90"
          width="90"
          radius={1}
          color="#008080"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Box>
    );

  return (
    <Stack align="center" spacing="5" my="8">
      <Avatar user={user} />
      <Code>{user?.username}</Code>
      <Button
        colorScheme="blue"
        w="full"
        as={Link}
        to={`${PROTECTED}/profile/${user?._id}`}
      >
        Edit Profile
      </Button>
      {user?.role?.toLowerCase() === "admin" && (
        <Button
          colorScheme="blue"
          w="full"
          as={Link}
          to={`${PROTECTED}/notification`}
        >
          <RxGear style={{ marginRight: "1px" }} />
          Notification
        </Button>
      )}
      {user?.role?.toLowerCase() === "user" && (
        <Button
        colorScheme="blue"
        w="full"
        as={Link}
        to={`${PROTECTED}/notification`}
      >
        <RxGear style={{ marginRight: "1px" }} />
        Notification
      </Button>
      )}
    </Stack>
  );
}

export default function Sidebar() {
  const { user, isLoading: userLoading } = useAuth();

  return (
    <Box
      px="6"
      height="85vh"
      w="100%"
      maxW="200px"
      borderRight="1px solid"
      borderRightColor="blue.100"
      position="sticky"
      overflow="auto"
      top="16"
      display={{ base: "none", md: "block" }}
    >
      <ActiveUser />
      <Box align="center">
        <Box as="ul" borderBottom="2px solid" borderColor="blue.200" />
        <Button
          variant="outline"
          colorScheme="red"
          as={Link}
          to={HOMEPAGE}
          mt="4"
          size="sm"
          w="full"
        >
          HOMEPAGE
        </Button>
      </Box>

      {user?.role?.toLowerCase() === "admin" && (
        <>
          <Box align="center">
            <Box as="ul" />
            <Button
              variant="outline"
              colorScheme="red"
              as={Link}
              to={USERS}
              mt="4"
              size="sm"
              w="full"
            >
              STAFF
            </Button>
          </Box>
        </>
      )}
      {user?.role?.toLowerCase() === "admin" && (
        <Box align="center">
          <Box as="ul" />
          <Button
            variant="outline"
            colorScheme="red"
            as={Link}
            to={ALLUSERS}
            mt="4"
            size="sm"
            w="full"
          >
            USERS
          </Button>
        </Box>
      )}

      <Box align="center">
        <Box as="ul" />
        <Button
          variant="outline"
          colorScheme="red"
          as={Link}
          to={CIRCLE}
          mt="4"
          size="sm"
          w="full"
        >
          CIRCLES
        </Button>
      </Box>
      <Box align="center">
        <Box as="ul" />
        <Button
          variant="outline"
          colorScheme="red"
          as={Link}
          to={CHATS}
          mt="4"
          size="sm"
          w="full"
        >
          CHAT
        </Button>
      </Box>
      <Box align="center">
        <Box as="ul" />
        <Button
          variant="outline"
          colorScheme="red"
          as={Link}
          to={CAREMANUALS}
          mt="4"
          size="sm"
          w="full"
        >
          CARE MANUALS
        </Button>
      </Box>
      {user?.role?.toLowerCase() === "admin" && (
        <>
          <Box align="center">
            <Box as="ul" />
            <Button
              variant="outline"
              colorScheme="red"
              as={Link}
              to={RESETPASSWORD}
              mt="4"
              size="sm"
              w="full"
            >
              RESET PASSWORD
            </Button>
          </Box>
          <Box align="center">
            <Box as="ul" />
              <Button
                variant="outline"
                colorScheme="red"
                as={Link}
                to={REGISTER}
                mt="4"
                size="sm"
                w="full"
              >
                ADD MEMBERS
              </Button>
          </Box>
          <Box align="center">
            <Box as="ul" />
            <Button
              variant="outline"
              colorScheme="red"
              as={Link}
              to={ARCHIVES}
              mt="4"
              size="sm"
              w="full"
            >
              ARCHIVES
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
