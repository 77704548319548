import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import PostsList from "components/post/PostsList";
import { useAuth } from "hooks/auth";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "services/api";
import Avatar from "./Avatar";
import EditProfile from "./EditProfile";
// import {
//   EditorState,
//   convertToRaw,
//   convertFromRaw,
//   ContentState,
// } from "react-draft-wysiwyg";
// import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { FaTrash } from "react-icons/fa";
import inviteTemplatehtml from "services/inviteTemplate";
import { PostServices } from "services/postServices";

export default function Profile() {
  const { id } = useParams();
  // const { posts, isLoading: postsLoading } = usePosts(id);
  // const { user, isLoading: userLoading } = useUser(id);
  const [refetch, setRefetch] = useState(0);
  const [userLoading, setUserLoading] = useState(true);
  const { user, isLoading: authLoading, getUser } = useAuth(refetch);
  const [userData, setUserData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenInvite,
    onOpen: onOpenInvite,
    onClose: onCloseInvite,
  } = useDisclosure();
  const [postList, setPostList] = useState([]);
  const [postsLoading, setPostLoading] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const toast = useToast();

  const [editorState, setEditorState] = useState();

  const getUserToken = async () => {
    try {
      const response = await axios.post(baseUrl + "/user/getUserToken", {
        userId: id,
      });
      // console.log("response: " + response.data.token);
      const { token } = response.data;

      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(
          inviteTemplatehtml(
            userData?.username,
            `${window.location.protocol}//${window.location.host}/invite/${token}`
          )
        )
      );
      setEditorState(EditorState.createWithContent(contentState));
    } catch (error) {
      toast({
        title: "Failed",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      onCloseInvite();
    }
  };

  // console.log("editorState", editorState);

  const sendInvite = async () => {
    try {
      const contentState = editorState?.getCurrentContent();
      const contentStateJson = convertToRaw(contentState);
      const contentHtml = draftToHtml(contentStateJson);

      // console.log("contentHtml", contentHtml);
      const response = await axios.post(baseUrl + "/user/inviteUser", {
        emailHtml: contentHtml,
        email: userData?.email,
      });

      const message = response.data;
      toast({
        title: "Invitation sent successfully",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });

      onCloseInvite();
    } catch (error) {
      toast({
        title: "Failed to send invite",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      onCloseInvite();
    }
  };

  // useEffect(() => {
  //   if (userData) {
  //     const contentState = ContentState.createFromBlockArray(
  //       convertFromHTML(
  //         inviteTemplatehtml(
  //           userData?.username,
  //           `${window.location.protocol}//${window.location.host}/invite`
  //         )
  //       )
  //     );
  //     setEditorState(EditorState.createWithContent(contentState));
  //   }
  // }, [userData]);
  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(baseUrl + "/user/getUserInfoById/" + id).then((res) => {
      if (res.data.status) {
        setUserData(res.data.result);
        setUserLoading(false);
      }
    });
  }, [id, refetch]);

  async function fetchPosts() {
    let newPosts = await PostServices.fetchPostByProfileId(id);
    setPostList(newPosts);
    setPostLoading(false);
  }

  useEffect(() => {
  fetchPosts();
  }, [id]);
  // console.log(postList);

  const deleteUserHandler = async () => {
    try {
      const config = {
        method: "delete",
        url: `${baseUrl}/user/deleteUser/${id}`,
      };
      // console.log(config);
      const { data } = await axios.request(config);
      // console.log(data);
      onClose();
      toast({
        title: "User Deleted",
        description: data.message,
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      navigate(-1); // -1 is used to navigate to the previous route
    } catch (error) {
      // console.log(error?.response?.data?.message);
      toast({
        title: "Failed to forgot password",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      onClose();
    }
  };

  // console.log("userData", userData);
  if (userLoading)
    return (
      <Text textAlign="center" mt="4">
        Loading...
      </Text>
    );
  return (
    <>
    <Stack spacing="5">
      <Flex p={["4", "6"]} pos="relative" align="center">
        <Flex pt="40px">
          {user?._id === id ? (
            <Avatar user={user} size="2xl" />
          ) : (
            <Avatar user={userData} size="2xl" />
          )}
        </Flex>

          {(user?._id === id || user?.role === "admin") && (
            <>
          <Button
            pos="absolute"
            mb="2"
            top="6"
            right="6"
            colorScheme="blue"
              onClick={() => {
                setShowDelete(false);
                setShowEdit(true);
                onOpen();
              }}
            size="sm"
          >
            Change avatar
          </Button>
            </>
        )}

        <Stack ml="10" pt="60px">
          {user?._id === id ? (
            <Text fontSize="2xl">{user?.username}</Text>
          ) : (
            <Text fontSize="2xl">{userData?.username}</Text>
          )}
            {user?.role === "admin" && (
            <>
              {user?._id === id ? (
                <Text fontSize="xl">{user?.email}</Text>
              ) : (          
                <Text fontSize="xl">{userData?.email}</Text>          
              )}
            </>
          )}
          <HStack spacing="10">
            <Text color="gray.700" fontSize={["sm", "lg"]}>
              Posts: {postList?.length}
            </Text>
              {user?._id === id ? (
            <Text color="gray.700" fontSize={["sm", "lg"]}>
              Joined: {new Date(user?.createdAt).toString().slice(0, 16)}
            </Text>
              ) : (
                <Text color="gray.700" fontSize={["sm", "lg"]}>
                  Joined: {new Date(userData?.createdAt).toString().slice(0, 16)}
                </Text>
              )}
          </HStack>
            {user?.role === "admin" && userData?.role === "user" && (
              <Button
                colorScheme="blue"
                onClick={() => {
                  getUserToken();
                  onOpenInvite();
                }}
                size="sm"
                isDisabled={userData?.invite === "joined" ? true : false}
              >
                {userData?.invite !== "joined" ? "Invite" : "Joined"}
              </Button>
            )}
        </Stack>

          {user?.role?.toLowerCase() === "admin" && user?._id !== id && (
            <>
            <IconButton
              ml="auto"
              onClick={() => {
                setShowEdit(false);
                setShowDelete(true);
                onOpen();
              }}
              size="md"
              colorScheme="red"
              variant="ghost"
              icon={<FaTrash />}
              isRound
            />
            </>
          )}
          {showEdit && (
        <EditProfile
          getUser={getUser}
          refetch={refetch}
          setRefetch={setRefetch}
          isOpen={isOpen}
          onClose={onClose}
              user={userData}
        />
          )}
      </Flex>
      <Divider />
        {user?._id !== id && userData?.bio && (
          <Box pl="10">
            <Text
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                textAlign: "center",
              }}
            >
              About Me
            </Text>
            <Text
              wordBreak="break-word"
              fontSize="md"
              padding={2}
              align={"center"}
            >
              {/* {post?.carePostBody} */}
              <div 
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(JSON.parse(userData?.bio)),
                }}
              />
            </Text>
          </Box>
        )}
        {user?._id === id && userData?.bio && (
          <Box pl="10">
            <Text
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                textAlign: "center",
              }}
            >
              About Me
            </Text>
            <Text
              wordBreak="break-word"
              fontSize="md"
              padding={2}
              align={"center"}
            >
              {/* {post?.carePostBody} */}
              <div
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(JSON.parse(user?.bio)),
                }}
              />
            </Text>
          </Box>
        )}
      {user?.role === "admin" && (
        <>
        {postsLoading ? (
          <Text textAlign={"center"} mt={"4"}>
            Posts are loading...
          </Text>
        ) : (
            <PostsList
              posts={postList}
              refetch={refetch}
              setRefetch={setRefetch}
            />
        )}
      </>
      )}
    </Stack>
      {showDelete && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Are you sure you want to delete ?</ModalHeader>
            <ModalCloseButton />

            <ModalFooter>
              <Button
                display={"block"}
                mx={"auto"}
                colorScheme="blue"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                display={"block"}
                mx={"auto"}
                colorScheme="red"
                onClick={() => deleteUserHandler(user?._id)}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Modal isOpen={isOpenInvite} onClose={onCloseInvite} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite {userData?.username}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box border="2px solid teal" marginTop={"5px"}>
              <Editor
                editorState={editorState}
                onEditorStateChange={(state) => {
                  // console.log("=========", state);
                  handleEditorChange(state);
                }}
                toolbar={{
                  options: ["inline", "list"],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: [
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "monospace",
                    ],
                  },
                  list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["unordered", "ordered"],
                  },
                }}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="red"
              onClick={onCloseInvite}
            >
              Cancel
            </Button>
            <Button
              display={"block"}
              mx={"auto"}
              colorScheme="blue"
              onClick={() => sendInvite()}
            >
              Invite
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
