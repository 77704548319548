import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  RadioGroup,
  Radio,
  HStack,
  Select,
} from "@chakra-ui/react";
import { CIRCLE, HOMEPAGE } from "lib/routes";
import { useRegister } from "hooks/auth";
import { useForm } from "react-hook-form";
import {
  emailValidate,
  passwordValidate,
  usernameValidate,
  roleValidate,
} from "utils/form-validate";
import { useState } from "react";

export default function Register() {
  const { register: signup, isLoading } = useRegister();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [role, setRole] = useState("");

  async function handleRegister(data) {
    // signup({
    //   username: data.username,
    //   email: data.email,
    //   password: data.password,
    //   role: data.role,
    //   redirectTo: HOMEPAGE,
    // });
    signup({
      data: {
        username: data.username,
        email: data.email,
        password: data.password,
        role: role,
      },
      redirectTo: CIRCLE,
    });
  }
  console.log(role);
  return (
    <Center w="100%" h="100vh">
      <Box mx="1" maxW="md" p="9" borderWidth="1px" borderRadius="lg">
        <Heading mb="4" size="lg" textAlign="center">
          Register
        </Heading>

        <form onSubmit={handleSubmit(handleRegister)}>
          <FormControl isInvalid={errors.email} py="2">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="user@email.com"
              {...register("email", emailValidate)}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.username} py="2">
            <FormLabel>Full Name</FormLabel>
            <Input
              placeholder="First and Last Name"
              {...register("username", usernameValidate)}
            />
            <FormErrorMessage>
              {errors.username && errors.username.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password} py="2">
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="Password"
              {...register("password", passwordValidate)}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          {/* <FormControl as='fieldset'>
            <FormLabel as='legend' htmlFor={null}>
              User Type:
            </FormLabel>
            <Input
              type="role"
              placeholder="Type admin staff other"
              {...register("role", roleValidate)}
            />
            <FormErrorMessage>
              {errors.role && errors.role.message}
            </FormErrorMessage>
   
          </FormControl> */}
          <Select
            onChange={(e) => setRole(e.target.value)}
            mt={"4"}
            placeholder="Select Role"
          >
            <option value="admin">Admin</option>
            <option value="staff">Staff</option>
            <option value="user">User</option>
          </Select>
          <Button
            mt="4"
            type="submit"
            colorScheme="blue"
            size="md"
            w="full"
            isLoading={isLoading}
            loadingText="Signing Up"
          >
            Register
          </Button>
        </form>
      </Box>
    </Center>
  );
}
